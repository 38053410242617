import {
    AfterViewChecked,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {MapsAPILoader} from "@agm/core";
import {EmpresaService} from "../services/empresa.service";
import {ActivatedRoute, Router} from "@angular/router";
import { FormControl, FormGroup, Validators} from "@angular/forms";




@Component({
    selector: 'app-empresa-localizacion-map',
    templateUrl: './empresa-localizacion-map.component.html',
    styleUrls: ['./empresa-localizacion-map.component.css']
})
export class EmpresaLocalizacionMapComponent implements OnInit, AfterViewChecked {
    title: string = 'Prueba Mapa';
    latitude: number = 0;
    longitude: number = 0;
    radio: number = 0;
    zoom: any;
    address: string;
    idCentro: any = '';
    private geoCoder;

    // DATOS DESDE EL PADRE
    @Input() distanciaHijo: number = 0;
    @Input() longitudHijo: number = 0;
    @Input() latitudHijo: number = 0;


    formularioCoordenadas: FormGroup;

    // EMITIR VALORES AL PADRE EMPRESA-LOCALIZACION
    @Output() Eventolongitud = new EventEmitter();
    @Output() Eventolatitud = new EventEmitter();
    @Output() Eventodireccion = new EventEmitter();
    @Output() Eventoradio = new EventEmitter();
    @Output() Eventoidcentro = new EventEmitter();


    @ViewChild('search') public searchElementRef: ElementRef;
    @ViewChild('latitudView') public latitudView: ElementRef;
    @ViewChild('longitudView') public longitudView: ElementRef;
    @ViewChild('radioView') public radioView: ElementRef;


    constructor(private mapsAPILoader: MapsAPILoader,
                private ngZone: NgZone,
                private empresaService: EmpresaService,
                private activatedRoute: ActivatedRoute,
                private router: Router
    ) {
    }

    ngOnInit() {
        this.loadPlaces();
        this.ObtenerParametroRuta();
        this.generarFormulario();
        this.EmitirEvento();
    }

    ngAfterViewChecked(): void {
        this.EmitirEvento();
    }


    // Emitir Eventos del Hijo al Padre
    EmitirEvento() {
        this.Eventolatitud.emit(this.latitude);
        this.Eventolongitud.emit(this.longitude);
        this.Eventodireccion.emit(this.address);
        this.Eventoradio.emit(this.radioView.nativeElement.value);
        this.Eventoidcentro.emit(this.idCentro);
    }

    ObtenerParametroRuta() {
        this.activatedRoute.params.subscribe(res => {
            if (res) {
                this.idCentro = res.id;
            } else {
                return;
            }
        });
    }

    generarFormulario() {
        this.formularioCoordenadas = new FormGroup({
            latitude: new FormControl(null, Validators.required),
            longitude: new FormControl(null, Validators.required),
            radio: new FormControl(this.distanciaHijo, Validators.required),
            idCentro: new FormControl(Number(this.idCentro))
        });

    }

    // Coordenadas() {
    //     if (this.latitudView.nativeElement.value == "" || this.longitudView.nativeElement.value == "" || this.radioView.nativeElement.value == "") {
    //         window.alert('Debe Rellenar los datos del Formulario');
    //     } else {
    //
    //         let configFichar = {};
    //         configFichar = {
    //             id_centro: this.idCentro,
    //             longitud: this.longitudView.nativeElement.value,
    //             latitud: this.latitudView.nativeElement.value,
    //             radio: this.radioView.nativeElement.value
    //         };
    //
    //         this.empresaService.updateConfigFicharEmpresa(configFichar).subscribe(res => {
    //             console.log('RESPUESTA', res);
    //             swal('Los datos se han guardado correctamente', {
    //                 icon: 'success',
    //                 timer: 1500
    //             }).then(r => r);
    //             this.router.navigate(['/admin/empresa']).then(r => r);
    //
    //         });
    //     }
    // }




// Get Current Location Coordinates
    setCurrentLocation() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.latitude = position.coords.latitude;
                this.longitude = position.coords.longitude;
                this.zoom = 8;
                this.getAddress(this.latitude, this.longitude);
            });
        }
    }

// Load Places Autocomplete
    loadPlaces() {
        this.mapsAPILoader.load().then(() => {
            this.setCurrentLocation();
            this.geoCoder = new google.maps.Geocoder;

            let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
                types: ["address"]
            });
            autocomplete.addListener("place_changed", () => {
                this.ngZone.run(() => {
                    //get the place result
                    let place: google.maps.places.PlaceResult = autocomplete.getPlace();

                    //verify result
                    if (place.geometry === undefined || place.geometry === null) {
                        return;
                    }

                    //set latitude, longitude and zoom
                    this.latitude = place.geometry.location.lat();
                    this.longitude = place.geometry.location.lng();
                    this.zoom = 12;
                    this.address = place.formatted_address;
                });
            });
        });

    }

// Get Address
    getAddress(latitude, longitude) {
        this.geoCoder.geocode({'location': {lat: latitude, lng: longitude}}, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    this.zoom = 12;
                    this.address = results[0].formatted_address;
                } else {
                    window.alert('No results found');
                }
            } else {
                window.alert('Geocoder failed due to: ' + status);
            }
        });
    }


    markerDragEnd($event: any) {
        this.latitude = $event.coords.lat;
        this.longitude = $event.coords.lng;
        this.getAddress(this.latitude, this.longitude);
    }


}
