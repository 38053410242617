<div class="card mb-3">
    <div class="card-header">
        <i class="fas fa-table"></i>
        Lista Empleados
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-striped" id="dataTable" width="100%" cellspacing="0" data-page-length="10"
                   data-paging="true">
                <thead class="thead-oscuro">
                <tr>
                    <th>Id</th>
                    <th>Imagen</th>
                    <th>Nombre
                        <input class="searchFilter" type="text" [(ngModel)]="nombre" placeholder="Buscar..."
                               (input)="filtrarNombre()">
                    </th>
                    <th>Apellidos
                        <input class="searchFilter" type="text" [(ngModel)]="apellidos" placeholder="Buscar..."
                               (input)="filtrarApellidos()">
                    </th>
                    <th>Dni
                        <input class="searchFilter" type="text" [(ngModel)]="dni" placeholder="Buscar..."
                               (input)="filtrarDNI()">
                    </th>
                    <th>Empresa
                        <input class="searchFilter" type="text" [(ngModel)]="empresa" placeholder="Buscar..."
                               (input)="filtrarEmpresa()">
                    </th>
                    <th>Departamento
                        <input class="searchFilter" type="text" [(ngModel)]="departamento" placeholder="Buscar..."
                               (input)="filtrarDepartamento()">
                    </th>
                    <th>Pin</th>
                    <th>Acciones</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let user of usersList | paginate: { itemsPerPage: 15, currentPage: actualPage }; let i = index">
                    <td (click)="verUser(user.id)">{{user.id}}</td>
                    <td (click)="verUser(user.id)">
                        <img class="media-object round-media img-thumbnail" [src]="user.img | images: ''"
                             style="height: 45px">
                    </td>
                    <td (click)="verUser(user.id)">{{user.nombreUser | titlecase}}</td>
                    <td (click)="verUser(user.id)">{{user.apellidos | titlecase}}</td>
                    <td (click)="verUser(user.id)">{{user.dni | titlecase}}</td>
                    <td (click)="verUser(user.id)">{{user.nombreEmpresa | titlecase}}</td>
                    <td (click)="verUser(user.id)">{{user.nombreDepartamento | titlecase}}</td>
                    <td (click)="verUser(user.id)">{{user.pin }}</td>
                    <td>
                        <button type="button" class="btn btn-outline-danger mr-1 btn-sm" (click)="removeUsuario(user.id)">
                            <i class="fas fa-trash fa-sm"></i>
                        </button>
                        <button type="button" class="btn btn-outline-dark btn-sm" (click)="verUser(user.id)">
                            <i class="fas fa-user"></i>
                        </button>

                    </td>
                </tr>
                </tbody>
            </table>
            <pagination-controls (pageChange)="actualPage = $event"></pagination-controls>
        </div>
    </div>
</div>
