<!--<mat-tab-group>-->
<!--    <mat-tab label="Datos">-->
<!--        <div class="container mt-5">-->
<!--            <app-datos (emisor)="dispararmodal($event)"></app-datos>-->
<!--            <button type="button" class="btn btn-dark d-block ml-auto mr-auto" (click)="editar()" *ngIf="role === 'ROLE_ADMIN'" [disabled]="fourthFormGroup.invalid || firstFormGroup.invalid || secondFormGroup.invalid" >Editar</button>-->
<!--        </div>-->
<!--    </mat-tab>-->
<!--    <mat-tab label="Contactos">-->
<!--        <div class="container mt-5">-->
<!--            <app-contacto (emisor)="dispararmodal($event)"></app-contacto>-->
<!--        </div>-->
<!--    </mat-tab>-->
<!--    <mat-tab label="Horarios">-->
<!--        <div class="container mt-5">-->
<!--            <app-horario (emisor)="dispararmodal($event)"></app-horario>-->
<!--        </div>-->
<!--    </mat-tab>-->
<!--    <mat-tab label="Estudios">-->
<!--        <div class="container mt-5">-->
<!--            <app-estudios (emisor)="dispararmodal($event)"></app-estudios>-->
<!--        </div>-->
<!--    </mat-tab>-->
<!--    <mat-tab label="Seguimiento">-->
<!--        <div class="container mt-5">-->
<!--            <app-control (pdfemisor)="dispararpdfmodal($event)"></app-control>-->
<!--        </div>-->
<!--    </mat-tab>-->
<!--</mat-tab-group>-->
<!--<app-my-modal [titulo]="ttlModal" [texto]="txtModal"></app-my-modal>-->
<!--<app-pdfmodal [user]="user" [empresa]="empresa" [fichajes]="arreglados" [inicio]="inicio" [fin]="fin" [arreglados]="arreglados"></app-pdfmodal>-->


<!----------------- HÉCTOR -------------------->

<mat-horizontal-stepper linear="false" #stepper>
    <mat-step label="Datos" >
        <div class="container mt-5">
            <app-datos (emisor)="dispararmodal($event)"></app-datos>
            <button type="button" class="btn btn-outline-dark d-block mr-0 ml-auto" (click)="guardar()" *ngIf="role === 'ROLE_ADMIN'" [disabled]="fourthFormGroup.invalid || firstFormGroup.invalid || secondFormGroup.invalid" >Guardar</button>
        </div>
    </mat-step>
    <mat-step label="Contacto" >
        <div class="container mt-5">
            <app-contacto (emisor)="dispararmodal($event)"></app-contacto>
        </div>
    </mat-step>
    <mat-step label="Horarios" >
        <div class="container mt-5">
            <app-horario (emisor)="dispararmodal($event)"></app-horario>
        </div>
    </mat-step>
    <mat-step label="Estudios" >
        <div class="container mt-5">
            <app-estudios (emisor)="dispararmodal($event)"></app-estudios>
        </div>
    </mat-step>
    <mat-step label="Seguimiento" >
        <div class="container mt-5">
            <app-control (pdfemisor)="dispararpdfmodal($event)"></app-control>
        </div>
    </mat-step>
</mat-horizontal-stepper>
<app-my-modal [titulo]="ttlModal" [texto]="txtModal"></app-my-modal>
<app-pdfmodal [user]="user" [empresa]="empresa" [fichajes]="arreglados" [inicio]="inicio" [fin]="fin" [arreglados]="arreglados"></app-pdfmodal>
