import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbDatepickerModule, NgbCarouselModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTabsModule } from '@angular/material/tabs';
import * as $ from 'jquery/dist/jquery.min.js';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';

// UTILES
import { PipesModule } from '../../pipes.module';

// TEMPLATE
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SharedComponent } from '../template/shared.component';

// ROUTING
import { SharedRoutingModule } from './shared.routing';

// MODULOS
import { AdminModule } from '../admin/admin.module';

// SERVICES
import { AuthGuardService } from '../services/auth-guard.service';
import { UploadService } from '../services/upload.service';

registerLocaleData(localeEs);

@NgModule({
    imports: [
        CommonModule,
        NgbModule.forRoot(),
        SharedRoutingModule,
        HttpClientModule,
        FormsModule,
        PipesModule,
        BrowserAnimationsModule,
        MatTabsModule,
        NgbDatepickerModule,
        AdminModule,
        NgbCarouselModule
    ],

    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        SharedComponent,
    ],

    providers: [
        AuthGuardService,
        UploadService,
    ],

    exports: []

})
export class SharedModule {
}
