<!--Login Page Starts-->
<!-- <section id="login">
    <div class="container-fluid">
        <div class="row full-height-vh">
            <div class="col-12 d-flex align-items-center justify-content-center">
                <div class="card card-inverse gradient-indigo-purple text-center width-400">
                    <div class="card-img overlap">
                        <img  id="logo" alt="element 06" class="mb-1" [src]="logo" width="80" height="80">
                    </div>
                    <div class="card-body">
                        <div class="card-block">
                            <h2>Login</h2>
                            <form novalidate="" (ngSubmit)="onSubmit()" #f="ngForm">
                                <div class="form-group">
                                    <div class="col-md-12">
                                        <input type="email" class="form-control" name="username" id="inputEmail" placeholder="Email" ngModel required email>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="col-md-12">
                                        <input type="password" class="form-control" name="password" id="inputPass" placeholder="Password" ngModel required>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="col-md-12">
                                        <button type="submit" class="btn btn-pink btn-block btn-raised">Entrar</button>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="float-left"><a (click)="onForgotPassword()">Recobrar password</a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!--Login Page Ends-->


<div class="container">

    <div class="card card-login mx-auto mt-5">
        <div class="card-header">
            Login
            <a [routerLink]="['/fichar']">
                <i class="fas fa-calendar-check text-dark"></i>
            </a>
        </div>
        <div class="card-body">
            <form novalidate="" (ngSubmit)="onSubmit()" #f="ngForm">
                <div class="form-group">
                    <div class="form-label-group">
                        <input type="email" class="form-control" name="username" id="inputEmail" placeholder="Email"
                               ngModel required email>
                        <label for="inputEmail">Email</label>
                    </div>
                </div>
                <div class="form-group">
                    <div class="form-label-group">
                        <input type="password" class="form-control" name="password" id="inputPass"
                               placeholder="Contraseña" ngModel required>
                        <label for="inputPass">Contraseña</label>
                    </div>
                </div>

                <div class="form-group">
                        <button type="submit" class="btn btn-dark btn-lg btn-block">Entrar</button>
                </div>
            </form>
            <div class="text-danger" style="text-align: center" *ngIf="this.error === true">
                <label>Email o contraseña incorrecta</label>
            </div>
            <div class="text-center">
                <!-- <a class="d-block small" (click)="onForgotPassword()">Recobrar password</a> -->
                <a class="d-block small text-primary" [routerLink]="['/public/forgot']">Recuperar password</a>
            </div>
        </div>
    </div>
</div>
