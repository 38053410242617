import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { LoginPageComponent } from './login/login-page.component';
import { ForgotPasswordPageComponent } from './forgot-password/forgot-password-page.component';
import { HttpClientModule } from '@angular/common/http';
import {FicharComponent} from "./fichar/fichar.component";

// ROUTING
import { PagesRoutingModule } from './pages-routing';

// SERVICES
import { LoginService } from './services/login.service';
import { CreatePasswordComponent } from './forgot-password/create-password.component';
import {NgbAlertModule} from "@ng-bootstrap/ng-bootstrap";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        PagesRoutingModule,
        HttpClientModule,
        NgbAlertModule,

    ],
    declarations: [
        LoginPageComponent,
        ForgotPasswordPageComponent,
        CreatePasswordComponent,
        FicharComponent
    ],
    providers: [
        LoginService
    ]
})
export class PagesModule { }
