<div class="modal fade" id="pdfMod" tabindex="-1" role="dialog" aria-labelledby="pdfModTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document"
         style="margin: 0 auto; max-height: 100%;">
        <div class="modal-content" style="max-height: 100%; margin: 0;">

            <div class="modal-header">
                <h5 class="modal-title" id="pdfModTitle">PDF Registro Seguimiento</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body" style="height: auto;">
                <div id="contenidopdf" *ngIf="user && fichajes && empresa" class="row py-3 px-5">

                    <div class="col-12 pb-2">
                        <h4>Resumen Registro Horas</h4>
                    </div>

                    <div class="col-12 pb-2">
                        <div class="col-12 pb-1">
                            <h5>Datos Empresa:</h5>
                            <span class="linea"></span>
                        </div>
                                                <div class="col-2 float-left p-0">
<!--                                                     <div class="pdfimg" [ngStyle]="{'background-image':(empresa.img && empresa.img !== '') ? 'url(' + rutaimg + empresa.img  + ')' :  'url(' + rutaimg + 'images/centro/alumno_default.jpg )'}"></div>-->
<!--                                                    <img class="pdfimg" [src]="(empresa.img && empresa.img !== '') ? (rutaimg + empresa.img) : rutaimg + 'images/centro/alumno_default.jpg'" alt="">-->
                                   <img class="pdfimg" src="../../../../../assets/img/avanza.jpg">
                                                </div>
                        <div class="col-10 float-left p-3">
                            <div class="col-6 float-left">{{empresa.nombre}}</div>
                            <div class="col-6 float-left">{{empresa.username}}</div>
                            <div class="col-12 float-left">{{empresa.direccion}} {{empresa.municipio}}</div>
                        </div>
                    </div>

                    <div class="col-12 pb-2">
                        <div class="col-12 pb-1">
                            <h5>Datos Empleado:</h5>
                            <span class="linea"></span>
                        </div>
                        <div class="col-12 float-left p-3">
                            <div class="col-1 float-left">Nº{{user.id}}</div>
                            <div class="col-5 float-left">{{user.nombre}} {{user.apellidos}}</div>
                            <div class="col-3 float-left">{{user.dni}}</div>
                            <div class="col-3 float-left">{{user.nacimiento}}</div>
                            <div class="col-6 float-left">{{user.direccion}} {{user.municipio}}</div>
                            <div class="col-6 float-right">{{user.username}}</div>
                            <div *ngIf="user.contactos.length > 0">
                                <div *ngFor="let contacto of user.contactos; let i = index;">
                                    <!--                                    <div class="col-3 float-left"></div>-->
                                    <div *ngIf="contacto.tipo === 2" class="col-3 float-right">{{contacto.dato}}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 pb-2" style="height: auto;">
                        <div class="col-12 pb-1">
                            <h5>Registros entre el {{inicio | date:'EEEE dd MMMM yyyy' | titlecase}}
                                y {{fin | date:'EEEE dd MMMM yyyy' | titlecase}}</h5>
                            <span class="linea"></span>
                        </div>

                        <table mat-table [dataSource]="arreglados" class="px-2" style="height: auto;">
                            <!-- Position Column -->
                            <ng-container matColumnDef="fecha">
                                <th mat-header-cell *matHeaderCellDef> Dia</th>
                                <td mat-cell
                                    *matCellDef="let element"> {{element.dia | date: 'EEEE dd/MM/yyyy' | titlecase }} </td>
                                <td mat-footer-cell *matFooterCellDef class="font-weight-bold"> Total Horas</td>
                            </ng-container>

                            <!-- Name Column -->
                            <ng-container matColumnDef="entrada">
                                <th mat-header-cell *matHeaderCellDef> Entrada</th>
                                <td mat-cell *matCellDef="let element"> {{element.entrada | date: 'HH:mm' }} </td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>

                            <!-- Weight Column -->
                            <ng-container matColumnDef="salida">
                                <th mat-header-cell *matHeaderCellDef> Salida</th>
                                <td mat-cell *matCellDef="let element">
                                    <div *ngIf="''+element.entrada !== ''+element.salida">
                                        {{element.salida | date: 'HH:mm' }}
                                    </div>
                                </td>
                                <td mat-footer-cell *matFooterCellDef></td>
                            </ng-container>

                            <!-- Symbol Column -->
                            <ng-container matColumnDef="horas">
                                <th mat-header-cell *matHeaderCellDef class="text-right"> Horas</th>
                                <td mat-cell *matCellDef="let element"
                                    class="text-right"> {{element.horas | minutos}} </td>
                                <td mat-footer-cell *matFooterCellDef
                                    class="font-weight-bold text-right"> {{getTotalCost() | minutos}} </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displaysColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displaysColumns;"></tr>
                            <tr mat-footer-row *matFooterRowDef="displaysColumns"></tr>
                        </table>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">
                    <i class="fas editSeguimiento fa-window-close"></i>
                </button>
                <button type="button" class="btn btn-dark" (click)="lanzarPdf()">
                    <i class="fas fa-file-download"></i>
                </button>
            </div>
        </div>
    </div>
</div>
