<div class="container-fluid ">
    <div class="row m-1">
        <div class="card col-6 bg-transparent border-0">
            <div class="card">
                <div class="card-body text-bold-300 p-2">
                    <h5 class="card-title mb-2 font-personalizada text-dark">Coordenadas</h5>
                    <p class="card-text font-personalizada">Latitud: {{empresas.latitud}}</p>
                    <p class="card-text font-personalizada">Longitud: {{empresas.longitud }}     </p>
                    <p class="card-text font-personalizada">Distancia: {{empresas.radio}} </p>
                </div>
            </div>
        </div>

        <div class="card text-dark col-6 bg-transparent border-0">
            <div class="card">
                <div class="card-body p-2">
                    <h5 class="card-title mb-2 font-personalizada text-dark">Datos Empresa</h5>
                    <p class="card-text font-personalizada">Id Centro:  {{empresas.id_centro}} </p>
                    <p class="card-text font-personalizada">Nombre Centro: {{ empresas.nombre | titlecase }} </p>
                    <p class="card-text font-personalizada">Dirección: {{empresas.direccion | titlecase}} </p>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="col-12 color-hassan-h">
    <app-empresa-localizacion-map [distanciaHijo]=distanciaPadre
                                  [latitudHijo]=latitudPadre
                                  [longitudHijo]=longitudPadre
                                  (Eventolatitud)="latitud=$event"
                                  (Eventolongitud)="longitud=$event"
                                  (Eventodireccion)="direccion=$event"
                                  (Eventoradio)="radio=$event"
                                  (Eventoidcentro)="idCentro=$event">
    </app-empresa-localizacion-map>
    <button type="button" (click)="modificarDatosEmpresa()" class="btn-dark btn-lg float-right m-3">Modificar</button>
</div>




