import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {ActivatedRoute} from '@angular/router';
import {EmpleadosService} from '../services/empleados.service';
import {RutasImg} from '../../../../../utiles/rutas';
import {CrudempleadosService} from '../services/crudempleados.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';

export interface Ficha {
    imagen: string;
    nombre: string;
    apellidos: string;
    fecha: Date;
    estado: string;
    distancia: number;
}

@Component({
    selector: 'app-seguimiento',
    templateUrl: './seguimiento.component.html'
})
export class SeguimientoComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    public fichados: any[] = [];
    public id: number;
    public fecha: string;
    public fechas: any[] = [];
    public config: any[] = [];
    public alerta: number;
    public rutaimg: any;
    public empresas: any[] = [];
    public departamentos: any[] = [];
    public empresa: number;
    public departamento: number;
    public hoy: Date;

    public fichadosSubscription = new Subscription();
    public fechasSubscription = new Subscription();
    public configSubscription = new Subscription();
    public empresasSubscription = new Subscription();
    public departamentosSubscription = new Subscription();

    public displaysColumns: string[] = ['imagen', 'nombre', 'apellidos', 'fecha', 'estado', 'distancia'];
    public dataSource: any;

    constructor(private _empleados: EmpleadosService, private _crud: CrudempleadosService) {
        this.hoy = new Date();
        this.alerta = 0;
        this.empresa = null;
        this.departamento = null;
        this.fecha = '';
        this.rutaimg = RutasImg;
        this._empleados.getConfig();
        this._crud.getEmpresas();
    }

    ngOnInit() {
        this.fichadosSubscription = this._empleados.fichados$.subscribe(res => {
            this.fichados = res;
            this.dataSource = new MatTableDataSource<Ficha>(res);
            this.dataSource.paginator = this.paginator;
        });
        this.configSubscription = this._empleados.config$.subscribe(res => {
            this.config = res;
        });
        this.fechasSubscription = this._empleados.fechas$.subscribe(res => {
            this.fechas = res;
        });
        this.empresasSubscription = this._crud.empresas$.subscribe(res => {
            this.empresas = res;
        });
        this.departamentosSubscription = this._crud.departamentos$.subscribe(res => {
            this.departamentos = res;
            const todos = {departnombre: 'Todos Los Departamentos', id: 0, centro: 0};
            this.departamentos.unshift(todos);
        });
    }

    ngOnDestroy() {
        this.fichadosSubscription.unsubscribe();
        this.configSubscription.unsubscribe();
        this.fechasSubscription.unsubscribe();
        this.empresasSubscription.unsubscribe();
        this.departamentosSubscription.unsubscribe();
    }

    porFecha(): void {
        this.hoy = new Date(this.fecha);
        this._empleados.getUsersSeguimiento(2, this.empresa, this.departamento, this.fecha);
    }

    porEmpresa(): void {
        this._crud.getDepartamentos(this.empresa);
    }

    porDepartamento(): void {
        this._empleados.getFechas(this.empresa, this.departamento);
    }

    empieza(fecha: string) {
        // const hoy = new Date().toISOString();
        const hoy = new Date().toISOString().substring(0, 10);
        if (fecha.startsWith(hoy)) {
            return true;
        } else {
            return false;
        }
    }

    colorDistancia(distancia: number, idCentro: number) {
        const centroUser = this.config[this.config.findIndex(x => x.id === idCentro)];
        if (distancia > centroUser.radio) {
            return 'fuera';
        }
        if (distancia < centroUser.radio) {
            return 'dentro';
        }
    }


}
