<div class="card mb-3">
    <div class="card-header">
        <i class="fas fa-table"></i>
        Lista Empresas
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-striped" id="dataTable" width="100%" cellspacing="0" data-page-length="10"
                   data-paging="true">
<!--            <table class="table table-striped table-dark" id="dataTable" width="100%" cellspacing="0" data-page-length="10"  data-paging="true">-->
                <thead class="thead-oscuro">
                <tr>
                    <th>ID </th>
                    <th>CIF</th>
                    <th>NOMBRE</th>
                    <th>DIRECCIÓN</th>
                    <th>PROVINCIA</th>
                    <th>MUNICIPIO</th>
                    <th>ACCIONES</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let empresa of empresas; let i = index">
                    <td (click)="verEmpresa(empresa.id)">{{empresa.id }}</td>
                    <td (click)="verEmpresa(empresa.id)">{{empresa.cif | titlecase}}</td>
                    <td (click)="verEmpresa(empresa.id)">{{empresa.nombre | titlecase}}</td>
                    <td (click)="verEmpresa(empresa.id)">{{empresa.direccion | titlecase}}</td>
                    <td (click)="verEmpresa(empresa.id)">{{empresa.provincia | titlecase}}</td>
                    <td (click)="verEmpresa(empresa.id)">{{empresa.municipio | titlecase}}</td>
                    <td>
                        <button type="button" class="btn btn-outline-danger" (click)="eliminarEmpresa(empresa.id)">
                            <i class="fas fa-trash"></i>
                        </button>

                        <button type="button" class="btn btn-outline-dark ml-2" (click)="verEmpresa(empresa.id)">
                            <i class="fas fa-map-marker-alt"></i>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>



<!-- /.container-fluid -->
