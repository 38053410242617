import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginPageComponent} from './login/login-page.component';
import {ForgotPasswordPageComponent} from './forgot-password/forgot-password-page.component';
import {AppComponent} from '../../app.component';
import {CreatePasswordComponent} from './forgot-password/create-password.component';
import {FicharComponent} from "./fichar/fichar.component";


const routes: Routes = [
  {
    path: 'public',
    component: AppComponent,
    children: [
      {
        path: 'login/',
        component: LoginPageComponent,
        data: {
          title: 'Login Page'
        }
      },
      {
        path: 'forgot',
        component: ForgotPasswordPageComponent,
        data: {
          title: 'Forgot password'
        }
      }
    ]
  },
  {
    path: 'fichar',
    component: FicharComponent,
    data: {
      title: 'Fichar'
    }
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {
}
