import { Component, ViewChild, OnDestroy, OnInit, ViewEncapsulation, Renderer2, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { Datos } from '../../../../../../modelos/datos.modelo';
import { CrudempleadosService } from '../../services/crudempleados.service';
import {LoginService} from "../../../../../pages/services/login.service";

declare var $;

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ContactoComponent implements OnInit, OnDestroy {
  @ViewChild('contactoForm') contactoForm: NgForm;
  @Output() public emisor = new EventEmitter<any>();
  public dato: Datos;
  public variable: any;
  public fantasma: any;
    role = '';

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _crud: CrudempleadosService,
    private renderer: Renderer2,
    private _login: LoginService
  ) {
    this.role = this._login.getIdentity().role;
    this.dato = {
      password: '',
      nombre: '',
      apellidos: '',
      direccion: '',
      nacimiento: null,
      dni: '',
      municipio: '',
      iban: '',
      username: '',
      id: null,
      id_municipio: 0,
      img: '',
      contactos: [],
      titulos: [],
      horarios: [],
      id_centro: 0,
      id_departamento: 0,
      pin: 0,
      contrato: 0,
      horas: 0,
    };
  }

  ngOnInit() {


  }

  ngOnDestroy() {

  }

  open(tipo, id) {
    const valor = $('#' + id).val();
    const elemento = $('#' + id);

    if (elemento[0].validity.valid) {
      if (this.dato.id === null) {
        const contacto = {
          dato: elemento.val(),
          tipo: tipo,
        };
        this.dato.contactos.push(contacto);
        this.fantasma = 'lleno';
        elemento.val('');
        this.controlPaso();
      } else {
        const contacto = {
          id: this.dato.id,
          dato: elemento.val(),
          tipo: tipo,
        };
        this._crud.addContacto(contacto);
        elemento.val('');
      }
    } else {
      elemento[0].classList.toggle('ng-invalid');
      elemento[0].classList.toggle('ng-pristine');
      elemento[0].classList.toggle('ng-touched');
      const alerta = {
        ttlModal: 'Alerta',
        txtModal: 'Revise los campos en rojo'
      };
      this.emisor.emit(alerta);
    }
  }

  controlValido(status, id) {
    const elemento = $('#' + id);
    if (!elemento[0].validity.valid) {
      if (!elemento[0].classList.contains('ng-invalid')) {
        elemento[0].classList.add('ng-invalid');
        elemento[0].classList.add('ng-pristine');
        elemento[0].classList.add('ng-touched');
      }
    } else {
      if (elemento[0].classList.contains('ng-invalid')) {
        elemento[0].classList.toggle('ng-invalid');
        elemento[0].classList.toggle('ng-pristine');
        elemento[0].classList.toggle('ng-touched');
      }
    }
  }

  borrar(i) {
    if (this.dato.id === null) {
      this.dato.contactos.splice(i, 1);
      this.controlPaso();
    } else {
      const idc = this.dato.contactos[i].id;
      this._crud.removeContacto(this.dato.id, idc);
      this.controlPaso();
    }
  }

  // PARA VERIFICAR QUE HAY MINIMO UN MOVIL
  controlPaso() {
    let haymovil = false;
    for (let i = 0; i < this.dato.contactos.length; i++) {
      if (this.dato.contactos[i].tipo === 2) {
        haymovil = true;
      }
    }
    if (this.dato.contactos.length === 0) {
      this.fantasma = undefined;
      const alerta = {
        ttlModal: 'Alerta',
        txtModal: 'Al menos debe introducir un email y un telefono'
      };
      this.emisor.emit(alerta);
    } else if (!haymovil) {
      this.fantasma = undefined;
      const alerta = {
        ttlModal: 'Alerta',
        txtModal: 'Al menos debe introducir un móvil'
      };
      this.emisor.emit(alerta);
    }
  }


}
