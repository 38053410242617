import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-forgot-password-page',
  templateUrl: './forgot-password-page.component.html',
  styleUrls: ['./forgot-password-page.component.scss']
})

export class ForgotPasswordPageComponent {
  @ViewChild('f') forogtPasswordForm: NgForm;

  constructor(private router: Router,
    private route: ActivatedRoute, private _login: LoginService) { }

  // On submit click, reset form fields
  onSubmit() {
    if (this.forogtPasswordForm.valid) {
      this._login.forgotPassword(this.forogtPasswordForm.value);
      this.forogtPasswordForm.reset();
    }

  }

  // On login link click
  onLogin() {
    this.router.navigate(['login'], { relativeTo: this.route.parent });
  }

  // On registration link click
  onRegister() {
    this.router.navigate(['register'], { relativeTo: this.route.parent });
  }
}
