


import {RouteInfo} from "../modelos/sidebar.modelo";

// configuracion para servidor local //
//  export const Rutas = 'http://localhost/ubik-me_Back/web/';
//  export const RutasImg = 'http://localhost/ubik-me_Back/web/';

// configuracion para servidor online //
export const Rutas = 'https://apipresencia.avanzaconsultoria.com/';
export const RutasImg = 'https://apipresencia.avanzaconsultoria.com/';

export const ROUTES: RouteInfo[] = [
    {
        path: '/admin/admin',
        title: 'Principal',
        icon: 'fas fa-home',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: '/admin/seguimiento',
        title: 'Seguimiento',
        icon: 'fas fa-user-check',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: '',
        title: 'Empleados',
        icon: 'fas fa-user',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [
            {
                path: '/admin/addempleado',
                title: 'Agregar Empleado',
                icon: 'fas fa-plus',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },
            {
                path: '/admin/empleados',
                title: 'Lista Empleados',
                icon: 'fas fa-list',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },
        ]
    },
    {
        path: '',
        title: 'Empresa',
        icon: 'fas fa-city',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [
            {
                path: '/admin/empresaadd',
                title: 'Agregar Empresa',
                icon: 'fas fa-plus',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },
            {
                path: '/admin/empresa',
                title: 'Lista Empresa',
                icon: 'fas fa-list',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },
        ]
    },
    {
        path: '',
        title: 'Departamento',
        icon: 'far fa-building',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [

            {
                path: '/admin/departamentoadd',
                title: 'Agregar Departamento',
                icon: 'fas fa-plus',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },
            {
                path: '/admin/departamento',
                title: 'Lista Departamento',
                icon: 'fas fa-list',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },
        ]
    },
];
