<div class="row">
    <div class="col-md-12 col-sm-12">

        <form class="form form-horizontal" novalidate="" #horarioForm="ngForm">
            <input type="text" class="form-control border-dark" name="fantasma" [(ngModel)]="fantasma" hidden required>

            <div class="col-md-12 col-sm-12 float-left ">
                <div class="input-group mb-5 crearHorarioRow" *ngIf="role === 'ROLE_ADMIN'">
                    <div class="input-group-prepend">
                        <button class="btn btn-dark opa-1" type="button" disabled>
                            <i class="fas fa-calendar-check"></i>
                        </button>
                    </div>

                    <select id="dia" class="form-control custom-select selectHorario" (blur)="controlValido('dia')"
                            (focus)="controlValido('dia')" required>
                        <option value="" selected>Dia de la semana</option>
                        <option *ngFor="let dia of dias; let i=index;" [(value)]="dia.valor">{{dia.nombre}}</option>
                    </select>

                    <!-- <div class="input-group-prepend">
                        <button class="btn btn-dark opa-1" type="button" disabled>
                            <i class="fas fa-check-circle"></i>
                        </button>
                    </div> -->
                    <div class="timePickerHorario">
                        Entrada
                        <ngb-timepicker id="in" [(ngModel)]="timeEntrada" name="entrada" (blur)="controlValido('in')" (focus)="controlValido('in')"></ngb-timepicker>
                    </div>
                    <!--                    <select id="in" class="form-control custom-select" (blur)="controlValido('in')" (focus)="controlValido('in')" required>-->
                    <!--                        <option value="" selected>Hora de Entrada</option>-->
                    <!--                        <option *ngFor="let hora of horas; let i=index;" (value)="hora.value">{{hora.nombre}}</option>-->
                    <!--                    </select>-->

                    <!-- <div class="input-group-prepend">
                        <button class="btn btn-dark opa-1" type="button" disabled>
                            <i class="fas fa-times-circle"></i>
                        </button>
                    </div> -->
                    <div class="timePickerHorario">
                        Salida
                        <ngb-timepicker id="out" [(ngModel)]="timeSalida" name="salida" (blur)="controlValido('out')" (focus)="controlValido('out')"></ngb-timepicker>
                    </div>
                    <!--                    <select id="out" class="form-control custom-select" (blur)="controlValido('out')" (focus)="controlValido('out')" required>-->
                    <!--                        <option value="" selected>Hora de Salida</option>-->
                    <!--                        <option *ngFor="let hora of horas; let i=index;" (value)="hora.value">{{hora.nombre}}</option>-->
                    <!--                    </select>-->

                    <!-- EJEMPLO DE INPUT QUE ABRE UN DATE PICKER DE ANGULAR MATERIAL
                        <input id="horain" class="form-control" [matDatepicker]="picker" placeholder="Hora de Entrada" type="text" (click)="picker.open()" (blur)="controlValido('titul')" (focus)="controlValido('titul')"
                        required>
                        <mat-datepicker #picker></mat-datepicker> -->

                    <!-- <div class="input-group-prepend">
                        <button class="btn btn-dark opa-1" type="button" disabled>
                            <i class="fas fa-clock"></i>
                        </button>
                    </div> -->

                    <input id="margen" class="form-control selectHorario" placeholder="Margen minutos para fichar"
                           type="number"
                           (blur)="controlValido('margen')"
                           (focus)="controlValido('margen')" required>

                    <div class="input-group-append">
                        <button class="btn btn-dark" type="button" (click)="open('dia', 'in', 'out', 'margen')">
                            <i class="fas fa-plus"></i>
                        </button>
                    </div>
                </div>

                <div class="list-group" *ngIf="dato.horarios && dato.horarios.length > 0">
                    <ng-container *ngFor="let horario of dato.horarios; let i = index;">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <button class="btn btn-dark opa-1" type="button" disabled>
                                    <i class="fas fa-calendar-check"></i>
                                </button>
                            </div>

                            <!-- ************ DIA ******************-->
                            <select  class="form-control custom-select" disabled>
                                <option value="horario.dia">{{diasemana(horario.dia)}}</option>
                            </select>
                            <!-- <input type="text" class="form-control border-dark" name="dia{{i}}" [(ngModel)]="dias[horario.dia].nombre" required readonly> -->

                            <!-- <div class="input-group-prepend">
                                <button class="btn btn-dark opa-1" type="button" disabled>
                                    <i class="fas fa-check-circle"></i>
                                </button>
                            </div> -->

                            <!-- ************  HORA ENTRADA ******************-->
                            <select *ngIf="horario.id" class="form-control custom-select" disabled>
                                <option value="dias[horario.dia].nombre">{{horario.inat}}</option>
                            </select>
                            <select  *ngIf="!horario.id"class="form-control custom-select" disabled>
                                <option value="dias[horario.dia].nombre">{{horario.inat.hour | number:'2.0-0' }}:{{horario.inat.minute  | number:'2.0-0'}}</option>
                            </select>
                            <!-- <input type="text" class="form-control border-dark" name="inat{{i}}" [(ngModel)]="horario.inat" required readonly> -->

                            <!-- <div class="input-group-prepend">
                                <button class="btn btn-dark opa-1" type="button" disabled>
                                    <i class="fas fa-times-circle"></i>
                                </button>
                            </div> -->

                            <!-- ************ HORA SALIDA ******************-->
                            <select *ngIf=horario.id class="form-control custom-select" disabled>
                            <option value="dias[horario.dia].nombre">{{horario.outat}}</option>
                        </select>

                            <select *ngIf=!horario.id class="form-control custom-select" disabled>
                                <option  value="dias[horario.dia].nombre">{{horario.outat.hour | number:'2.0-0' }}:{{horario.outat.minute | number: '2.0-0' }}</option>
                            </select>
                            <!-- <input type="text" class="form-control border-dark" name="outat{{i}}" [(ngModel)]="horario.outat" required readonly> -->

                            <!-- <div class="input-group-prepend">
                                <button class="btn btn-dark opa-1" type="button" disabled>
                                    <i class="fas fa-clock"></i>
                                </button>
                            </div> -->

                            <!-- ************ MARGEN ******************-->
                            <input type="text" class="form-control" name="margen{{i}}" [(ngModel)]="horario.margen"
                                   required readonly>

                            <div class="input-group-append" id="button-addon4" *ngIf="role === 'ROLE_ADMIN'">
                                <button class="btn btn-outline-danger" type="button" (click)="borrar(i)">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

        </form>
    </div>

</div>
