<div class="card mb-3">
    <div class="card-header">
        <i class="fas fa-table"></i>
        Seguimiento Empleados
    </div>
    <!--    POR EMPRESA SIN CAROUSEL   -->
    <div *ngFor="let empresa of numeroEmpresas; let i = index">
        <h5 class="tituloSinCarousel mt-4">{{empresa.nombreEmpresa | titlecase }}</h5>
        <div *ngIf="fichados.length > 0" class="">
            <div class="row p-3">
                <div *ngFor="let user of fichados; let i = index; let primero = first;">
                    <div class="" *ngIf="user.id_centro === empresa.id" [ngClass]="{active: primero}">
                        <div class="cartaFichadaSinCarousel m-2 handClick"
                             [ngClass]="{cartaSinCarousel:(empieza(user.fecha)  && user.estado === 'ENTRADA'), cartaSinCarouselSalida:(empieza(user.fecha)  && user.estado === 'SALIDA')}"
                             (click)="verUser(user.id_user)">
                            <div class="targetaSinCarousel"
                                 [ngClass]="{targetaFuera:(empieza(user.fecha)  && user.estado === 'ENTRADA'), targetaFueraSalida:(empieza(user.fecha)  && user.estado === 'SALIDA')}"
                                 [ngStyle]="{ 'background-image':'url(' + rutaimg + user.img  + ')' }"></div>
                            <div class="card-body centrado">
                                <h5>{{user.nombre | titlecase}}</h5>
                                <h6>{{user.apellidos | titlecase}}</h6>
                                <p class="card-text">{{user.fecha | date:'HH:mm' | seguimiento: empieza(user.fecha):true}} {{user.estado | seguimiento:
                                    empieza(user.fecha):false}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--    POR EMPRESA   -->
    <!--
        <div *ngFor="let empresa of numeroEmpresas; let i = index">
            <h5 class="tituloCarousel">{{empresa.nombreEmpresa}}</h5>
            <div class="card-body">
                <div *ngIf="fichados.length > 0" id="carusel" class="carousel multi slide col-md-3 col-sm-4 col-xs-6">
                    <div class="carousel-inner">
                        <div class="carousel-item" *ngFor="let user of fichados; let i = index; let primero = first;"
                             [ngClass]="{active: primero}">
                            <div class="card cartaFichada"
                                 *ngIf="user.id_centro === empresa.id"
                                 [ngClass]="{carta:(empieza(user.fecha)  && user.estado === 'ENTRADA')}">
                                <div class="targeta"
                                     [ngClass]="{targetaFuera:(empieza(user.fecha)  && user.estado === 'ENTRADA')}"
                                     [ngStyle]="{ 'background-image':'url(' + rutaimg + user.img  + ')' }"></div>
                                <div class="card-body centrado">
                                    <h5 class="card-title">{{user.nombre}}</h5>
                                    <h6 class="card-title">{{user.apellidos}}</h6>
                                    <p class="card-text">{{user.fecha | date:'HH:mm' | seguimiento: empieza(user.fecha):true}} {{user.estado | seguimiento:
                                        empieza(user.fecha):false}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="fichados.length > 0" id="carusel" class="carousel multi slide col-md-3 col-sm-4 col-xs-6">
                    <div class="carousel-inner">
                        <div class="carousel-item"
                             *ngFor="let user of fichados | reordenar:1; let i = index; let prime = first;"
                             [ngClass]="{active: prime}">
                            <div class="card cartaFichada"
                                 *ngIf="user.id_centro === empresa.id"
                                 [ngClass]="{carta:(empieza(user.fecha)  && user.estado === 'ENTRADA')}">
                                <div class="targeta"
                                     [ngClass]="{targetaFuera:(empieza(user.fecha)  && user.estado === 'ENTRADA')}"
                                     [ngStyle]="{'background-image':'url(' + rutaimg + user.img  + ')'}"></div>
                                <div class="card-body centrado">
                                    <h5 class="card-title">{{user.nombre}}</h5>
                                    <h6 class="card-title">{{user.apellidos}}</h6>
                                    <p class="card-text">{{user.fecha | date:'HH:mm' | seguimiento: empieza(user.fecha):true}} {{user.estado | seguimiento:
                                        empieza(user.fecha):false}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="fichados.length > 0" id="carusel" class="carousel multi slide col-md-3 col-sm-4 col-xs-6">
                    <div class="carousel-inner">
                        <div class="carousel-item"
                             *ngFor="let user of fichados | reordenar:2; let i = index; let prime = first;"
                             [ngClass]="{active: prime}">
                            <div class="card cartaFichada"
                                 *ngIf="user.id_centro === empresa.id"
                                 [ngClass]="{carta:(empieza(user.fecha)  && user.estado === 'ENTRADA')}">
                                <div class="targeta"
                                     [ngClass]="{targetaFuera:(empieza(user.fecha)  && user.estado === 'ENTRADA')}"
                                     [ngStyle]="{'background-image':'url(' + rutaimg + user.img  + ')'}"></div>
                                <div class="card-body centrado">
                                    <h5 class="card-title">{{user.nombre}}</h5>
                                    <h6 class="card-title">{{user.apellidos}}</h6>
                                    <p class="card-text">{{user.fecha | date:'HH:mm' | seguimiento: empieza(user.fecha):true}} {{user.estado | seguimiento:
                                        empieza(user.fecha):false}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="fichados.length > 0" id="carusel" class="carousel multi slide col-md-3 col-sm-4 col-xs-6">
                    <div class="carousel-inner">
                        <div class="carousel-item"
                             *ngFor="let user of fichados | reordenar:3; let i = index; let prime = first;"
                             [ngClass]="{active: prime}">
                            <div class="card cartaFichada"
                                 *ngIf="user.id_centro === empresa.id"
                                 [ngClass]="{carta:(empieza(user.fecha)  && user.estado === 'ENTRADA')}">
                                <div class="targeta"
                                     [ngClass]="{targetaFuera:(empieza(user.fecha)  && user.estado === 'ENTRADA')}"
                                     [ngStyle]="{'background-image':'url(' + rutaimg + user.img  + ')'}"></div>
                                <div class="card-body centrado">
                                    <h5 class="card-title">{{user.nombre}}</h5>
                                    <h6 class="card-title">{{user.apellidos}}</h6>
                                    <p class="card-text">{{user.fecha | date:'HH:mm' | seguimiento: empieza(user.fecha):true}} {{user.estado | seguimiento:
                                        empieza(user.fecha):false}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    -->

    <!--    POR ROL    -->
    <!-- <div *ngIf="empleados">
         <h5>Empleados</h5>
         <div class="card-body">
             <div *ngIf="fichados.length > 0" id="carusel" class="carousel multi slide col-md-3 col-sm-4 col-xs-6">
                 <div class="carousel-inner">
                     <div class="carousel-item" *ngFor="let user of fichados; let i = index; let primero = first;"
                          [ngClass]="{active: primero}">
                         <div class="card cartaFichada"
                              *ngIf="user.id_role === '2'"
                              [ngClass]="{carta:(empieza(user.fecha)  && user.estado === 'ENTRADA')}">
                             <div class="targeta"
                                  [ngClass]="{targetaFuera:(empieza(user.fecha)  && user.estado === 'ENTRADA')}"
                                  [ngStyle]="{ 'background-image':'url(' + rutaimg + user.img  + ')' }"></div>
                             <div class="card-body centrado">
                                 <h5 class="card-title">{{user.nombre}}</h5>
                                 <h6 class="card-title">{{user.apellidos}}</h6>
                                 <p class="card-text">{{user.fecha | date:'HH:mm' | seguimiento: empieza(user.fecha):true}} {{user.estado | seguimiento:
                                     empieza(user.fecha):false}}
                                 </p>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
             <div *ngIf="fichados.length > 0" id="carusel" class="carousel multi slide col-md-3 col-sm-4 col-xs-6">
                 <div class="carousel-inner">
                     <div class="carousel-item"
                          *ngFor="let user of fichados | reordenar:1; let i = index; let prime = first;"
                          [ngClass]="{active: prime}">
                         <div class="card cartaFichada"
                              *ngIf="user.id_role === '2'"
                              [ngClass]="{carta:(empieza(user.fecha)  && user.estado === 'ENTRADA')}">
                             <div class="targeta"
                                  [ngClass]="{targetaFuera:(empieza(user.fecha)  && user.estado === 'ENTRADA')}"
                                  [ngStyle]="{'background-image':'url(' + rutaimg + user.img  + ')'}"></div>
                             <div class="card-body centrado">
                                 <h5 class="card-title">{{user.nombre}}</h5>
                                 <h6 class="card-title">{{user.apellidos}}</h6>
                                 <p class="card-text">{{user.fecha | date:'HH:mm' | seguimiento: empieza(user.fecha):true}} {{user.estado | seguimiento:
                                     empieza(user.fecha):false}}
                                 </p>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
             <div *ngIf="fichados.length > 0" id="carusel" class="carousel multi slide col-md-3 col-sm-4 col-xs-6">
                 <div class="carousel-inner">
                     <div class="carousel-item"
                          *ngFor="let user of fichados | reordenar:2; let i = index; let prime = first;"
                          [ngClass]="{active: prime}">
                         <div class="card cartaFichada"
                              *ngIf="user.id_role === '2'"
                              [ngClass]="{carta:(empieza(user.fecha)  && user.estado === 'ENTRADA')}">
                             <div class="targeta"
                                  [ngClass]="{targetaFuera:(empieza(user.fecha)  && user.estado === 'ENTRADA')}"
                                  [ngStyle]="{'background-image':'url(' + rutaimg + user.img  + ')'}"></div>
                             <div class="card-body centrado">
                                 <h5 class="card-title">{{user.nombre}}</h5>
                                 <h6 class="card-title">{{user.apellidos}}</h6>
                                 <p class="card-text">{{user.fecha | date:'HH:mm' | seguimiento: empieza(user.fecha):true}} {{user.estado | seguimiento:
                                     empieza(user.fecha):false}}
                                 </p>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
             <div *ngIf="fichados.length > 0" id="carusel" class="carousel multi slide col-md-3 col-sm-4 col-xs-6">
                 <div class="carousel-inner">
                     <div class="carousel-item"
                          *ngFor="let user of fichados | reordenar:3; let i = index; let prime = first;"
                          [ngClass]="{active: prime}">
                         <div class="card cartaFichada"
                              *ngIf="user.id_role === '2'"
                              [ngClass]="{carta:(empieza(user.fecha)  && user.estado === 'ENTRADA')}">
                             <div class="targeta"
                                  [ngClass]="{targetaFuera:(empieza(user.fecha)  && user.estado === 'ENTRADA')}"
                                  [ngStyle]="{'background-image':'url(' + rutaimg + user.img  + ')'}"></div>
                             <div class="card-body centrado">
                                 <h5 class="card-title">{{user.nombre}}</h5>
                                 <h6 class="card-title">{{user.apellidos}}</h6>
                                 <p class="card-text">{{user.fecha | date:'HH:mm' | seguimiento: empieza(user.fecha):true}} {{user.estado | seguimiento:
                                     empieza(user.fecha):false}}
                                 </p>
                             </div>
                         </div>
                     </div>
                 </div>
                 &lt;!&ndash; <a class="carousel-control-prev" href="#carusel" role="button" data-slide="prev">
                     <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                     <span class="sr-only">Previous</span>
                 </a>
                 <a id="siguiente" class="carousel-control-next" href="#carusel" role="button" data-slide="next">
                     <span class="carousel-control-next-icon" aria-hidden="true"></span>
                     <span class="sr-only">Next</span>
                 </a> &ndash;&gt;
             </div>
             &lt;!&ndash; <div *ngFor="let user of fichados; let i = index" class="floatL col-xs-12 col-sm-4 col-md-3">
                 <div class="card cartaFichada" [ngClass]="{carta:empieza(user.fecha)}">
                     <div class="targeta" [ngClass]="{targetaFuera:empieza(user.fecha)}" [ngStyle]="{'background-image':'url(' + rutaimg + user.img  + ')'}"></div>
                     <div class="card-body centrado">
                         <h5 class="card-title">{{user.nombre}}</h5>
                         <h6 class="card-title">{{user.apellidos}}</h6>
                         <p class="card-text">{{user.fecha | date:'HH:mm' | seguimiento: empieza(user.fecha):true}}  {{user.estado | seguimiento: empieza(user.fecha):false}}</p>
                     </div>
                 </div>
             </div> &ndash;&gt;
         </div>
     </div>

     <div *ngIf="administracion">
         <h5>Administración</h5>
         <div class="card-body">
             <div *ngIf="fichados.length > 0" id="carusel" class="carousel multi slide col-md-3 col-sm-4 col-xs-6">
                 <div class="carousel-inner">
                     <div class="carousel-item" *ngFor="let user of fichados; let i = index; let primero = first;"
                          [ngClass]="{active: primero}">
                         <div class="card cartaFichada"
                              *ngIf="user.id_role === '3'"
                              [ngClass]="{carta:(empieza(user.fecha)  && user.estado === 'ENTRADA')}">
                             <div class="targeta"
                                  [ngClass]="{targetaFuera:(empieza(user.fecha)  && user.estado === 'ENTRADA')}"
                                  [ngStyle]="{ 'background-image':'url(' + rutaimg + user.img  + ')' }"></div>
                             <div class="card-body centrado">
                                 <h5 class="card-title">{{user.nombre}}</h5>
                                 <h6 class="card-title">{{user.apellidos}}</h6>
                                 <p class="card-text">{{user.fecha | date:'HH:mm' | seguimiento: empieza(user.fecha):true}} {{user.estado | seguimiento:
                                     empieza(user.fecha):false}}
                                 </p>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
             <div *ngIf="fichados.length > 0" id="carusel" class="carousel multi slide col-md-3 col-sm-4 col-xs-6">
                 <div class="carousel-inner">
                     <div class="carousel-item"
                          *ngFor="let user of fichados | reordenar:1; let i = index; let prime = first;"
                          [ngClass]="{active: prime}">
                         <div class="card cartaFichada"
                              *ngIf="user.id_role === '3'"
                              [ngClass]="{carta:(empieza(user.fecha)  && user.estado === 'ENTRADA')}">
                             <div class="targeta"
                                  [ngClass]="{targetaFuera:(empieza(user.fecha)  && user.estado === 'ENTRADA')}"
                                  [ngStyle]="{'background-image':'url(' + rutaimg + user.img  + ')'}"></div>
                             <div class="card-body centrado">
                                 <h5 class="card-title">{{user.nombre}}</h5>
                                 <h6 class="card-title">{{user.apellidos}}</h6>
                                 <p class="card-text">{{user.fecha | date:'HH:mm' | seguimiento: empieza(user.fecha):true}} {{user.estado | seguimiento:
                                     empieza(user.fecha):false}}
                                 </p>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
             <div *ngIf="fichados.length > 0" id="carusel" class="carousel multi slide col-md-3 col-sm-4 col-xs-6">
                 <div class="carousel-inner">
                     <div class="carousel-item"
                          *ngFor="let user of fichados | reordenar:2; let i = index; let prime = first;"
                          [ngClass]="{active: prime}">
                         <div class="card cartaFichada"
                              *ngIf="user.id_role === '3'"
                              [ngClass]="{carta:(empieza(user.fecha)  && user.estado === 'ENTRADA')}">
                             <div class="targeta"
                                  [ngClass]="{targetaFuera:(empieza(user.fecha)  && user.estado === 'ENTRADA')}"
                                  [ngStyle]="{'background-image':'url(' + rutaimg + user.img  + ')'}"></div>
                             <div class="card-body centrado">
                                 <h5 class="card-title">{{user.nombre}}</h5>
                                 <h6 class="card-title">{{user.apellidos}}</h6>
                                 <p class="card-text">{{user.fecha | date:'HH:mm' | seguimiento: empieza(user.fecha):true}} {{user.estado | seguimiento:
                                     empieza(user.fecha):false}}
                                 </p>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
             <div *ngIf="fichados.length > 0" id="carusel" class="carousel multi slide col-md-3 col-sm-4 col-xs-6">
                 <div class="carousel-inner">
                     <div class="carousel-item"
                          *ngFor="let user of fichados | reordenar:3; let i = index; let prime = first;"
                          [ngClass]="{active: prime}">
                         <div class="card cartaFichada"
                              *ngIf="user.id_role === '3'"
                              [ngClass]="{carta:(empieza(user.fecha)  && user.estado === 'ENTRADA')}">
                             <div class="targeta"
                                  [ngClass]="{targetaFuera:(empieza(user.fecha)  && user.estado === 'ENTRADA')}"
                                  [ngStyle]="{'background-image':'url(' + rutaimg + user.img  + ')'}"></div>
                             <div class="card-body centrado">
                                 <h5 class="card-title">{{user.nombre}}</h5>
                                 <h6 class="card-title">{{user.apellidos}}</h6>
                                 <p class="card-text">{{user.fecha | date:'HH:mm' | seguimiento: empieza(user.fecha):true}} {{user.estado | seguimiento:
                                     empieza(user.fecha):false}}
                                 </p>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
         </div>
     </div>-->
</div>
