<!--Forgot Password Starts-->
<section id="forgot-password">
    <div class="container-fluid gradient-red-pink">
        <div class="row full-height-vh">
            <div class="col-12 d-flex align-items-center justify-content-center">
                <div class="card bg-blue-grey bg-darken-3 px-4">
                    <div class="card-header">
                        <div class="card-image text-center">
                            <i class="icon-key font-large-5 blue-grey lighten-4"></i>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="card-block">
                            <div class="text-center">
                                <h4 class="text-uppercase text-bold-400 white">Forgot Password</h4>
                            </div>
                            <form class="pt-4" (ngSubmit)="onSubmit()" #f="ngForm">
                                <div class="form-group">
                                    <input type="email" class="form-control" name="inputEmail" id="inputEmail" placeholder="Your Email Address" ngModel>
                                </div>
                                <div class="form-group pt-2">
                                    <div class="text-center mt-3">
                                        <button type="button" class="btn btn-danger btn-raised btn-block">Submit</button>
                                        <button type="button" class="btn btn-secondary btn-raised btn-block">Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="card-footer bg-blue-grey bg-darken-3">
                            <div class="float-left white"><a (click)="onLogin()">Login</a></div>
                            <div class="float-right white">New User? <a (click)="onRegister()">Register Now</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Forgot Password Ends-->