import {Component, OnInit, Output} from '@angular/core';
import {EmpresaService} from "../services/empresa.service";
import {ActivatedRoute, Router} from "@angular/router";
import swal from "sweetalert";


@Component({
    selector: 'app-empresa-localizacion',
    templateUrl: './empresa-localizacion.component.html',
    styleUrls: ['./empresa-localizacion.component.css']
})
export class EmpresaLocalizacionComponent implements OnInit {

    latitud: string = '';
    longitud: string = '';
    radio: string = '';
    direccion: string = '';
    empresas: any = [];
    id = 1;
    idCentro: string = '';

    distanciaPadre: number;
    longitudPadre: number;
    latitudPadre: number;


    constructor(private empresaService: EmpresaService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
    ) {

    }

    ngOnInit() {
        /***** OBTENEMOS EL PARAMETRO DE LA RUTA ***/
        this.activatedRoute.params.subscribe(res => {
            if (res) {
                this.getEmpresa(res.id);
            } else {
                return;
            }
        });
    }


    getEmpresa(id: any) {
        this.empresaService.getEmpresaPorId(id).subscribe((empresa: any) => {

            this.empresas = empresa['id'];
            this.distanciaPadre = empresa.id.radio;
            this.longitudPadre = empresa.id.longitud;
            this.latitudPadre = empresa.id.latitud;
        });
    }


    modificarDatosEmpresa() {
        let configFichar = {};
        configFichar = {
            id_centro: this.idCentro,
            longitud: this.longitud,
            latitud: this.latitud,
            radio: this.radio,
            direccion: this.direccion,
        };

        this.empresaService.updateConfigFicharEmpresa(configFichar).subscribe(res => {
            swal('Los datos se han guardado correctamente', {
                icon: 'success',
                timer: 1500
            }).then(r => r);
            this.router.navigate(['/admin/empresa']).then(r => r);
        });

    }
}



