import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {Router, ActivatedRoute} from '@angular/router';
import {User} from '../../../../../modelos/user.modelo';
import {EmpleadosService} from '../services/empleados.service';
import swal from "sweetalert";


declare const $: any;

@Component({
    selector: 'app-empleados',
    templateUrl: './empleados.component.html',
    styleUrls: ['./empleados.component.css']
})
export class EmpleadosComponent implements OnInit, OnDestroy {
    tipo: string;
    aula: number;
    users: Array<User> = [];
    usersList: Array<User> = [];
    id: number;
    usersSubscription: Subscription = null;

    nombre = '';
    apellidos = '';
    empresa = '';
    departamento = '';
    dni = '';
    actualPage: any;

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _empleados: EmpleadosService) {
        const role = 2;
        this.actualPage = 1;
        this._empleados.getEmpleados(role);
        this.usersSubscription = this._empleados.users$.subscribe(res => {
            this.users = res;
            this.usersList = res;
        });
        // console.log('ID CONSTRUCTOR', this.id);
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.usersSubscription.unsubscribe();
    }

    verUser(id) {
        // console.log('VER USER ID', id);
        this._router.navigate(['/admin/editempleado', id]);
    }

    removeUsuario(id: Number) {
        const usuario = this.users[this.users.findIndex(usuario => usuario.id === id)];
        // @ts-ignore
        swal({
            title: 'BORRAR',
            text: '¿Seguro que quiere borrar a ' + usuario.nombreUser + ' ' + usuario.apellidos + '?',
            icon: 'error',
            buttons: true,
            dangerMode: true
        }).then((willDelete) => {
            if (willDelete) {
                this._empleados.removeEmpleado(id).subscribe(res => {
                    if (res['code'] === 200) {
                        swal('Se ha eliminado a ' + usuario.nombreUser + ' ' + usuario.apellidos + '.', {
                            icon: 'success',
                            timer: 3000
                        });
                        this.users.splice(this.users.findIndex(usuario => usuario.id === id), 1);
                    } else {
                        swal({
                            title: "ERROR",
                            text: "No se ha podido eliminar el usuario.",
                            icon: "error",
                            timer: 3000
                        });
                    }
                }, error => {
                    // console.log(error);
                });
            }
        });
    }

    /*****************************FILTROS**********************/
    filtrarNombre() {
        if (!this.nombre) {
            this.usersList = Object.assign([], this.users);
        }
        if (this.nombre) {
            this.usersList = Object.assign([], this.users).filter(
                item => item.nombreUser.toLowerCase().indexOf(this.nombre.toLowerCase()) > -1
            )
        }
        this.apellidos = '';
        this.empresa = '';
        this.departamento = '';
        this.dni = '';
    }

    filtrarApellidos() {
        if (!this.apellidos) {
            this.usersList = Object.assign([], this.users);
        }
        if (this.apellidos) {
            this.usersList = Object.assign([], this.users).filter(
                item => item.apellidos.toLowerCase().indexOf(this.apellidos.toLowerCase()) > -1
            )
        }
        this.nombre = '';
        this.empresa = '';
        this.departamento = '';
        this.dni = '';
    }

    filtrarDNI() {
        if (!this.dni) {
            this.usersList = Object.assign([], this.users);
        }
        if (this.dni) {
            this.usersList = Object.assign([], this.users).filter(
                item => item.dni.toLowerCase().indexOf(this.dni.toLowerCase()) > -1
            )
        }
        this.nombre = '';
        this.apellidos = '';
        this.empresa = '';
        this.departamento = '';
    }

    filtrarEmpresa() {
        if (!this.empresa) {
            this.usersList = Object.assign([], this.users);
        }
        if (this.empresa) {
            this.usersList = Object.assign([], this.users).filter(
                item => item.nombreEmpresa.toLowerCase().indexOf(this.empresa.toLowerCase()) > -1
            )
        }
        this.nombre = '';
        this.apellidos = '';
        this.departamento = '';
        this.dni = '';
    }

    filtrarDepartamento() {
        if (!this.departamento) {
            this.usersList = Object.assign([], this.users);
        }
        if (this.departamento) {
            this.usersList = Object.assign([], this.users).filter(
                item => item.nombreDepartamento.toLowerCase().indexOf(this.departamento.toLowerCase()) > -1
            )
        }
        this.nombre = '';
        this.apellidos = '';
        this.empresa = '';
        this.dni = '';
    }

    /*****************************FIN FILTROS**********************/

}
