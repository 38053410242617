import {Injectable} from '@angular/core';
import {Rutas} from "../../../../../utiles/rutas";
import {HttpClient} from "@angular/common/http";
import {Subject} from "rxjs/Subject";

@Injectable({
    providedIn: 'root'
})
export class DepartamentoServiceService {

    private departamentosSource = new Subject<any>();
    public departamentos$ = this.departamentosSource.asObservable();

    constructor(public http: HttpClient) {
    }

    getDepartamentos() {
        this.http.get(Rutas + 'get/departamentos?token=' + localStorage.getItem('token')).subscribe(res => {
            if (res['code'] === 200) {
                this.departamentosSource.next(res['id']);
            }
        }, error => {
         /*   console.log('estas imprimiendo errrorrrr' + error);*/
            return error;
        });
    }

    removeDepartamento(id: number) {
        return this.http.get(Rutas + 'remove/departamento?id=' + id + '&token=' + localStorage.getItem('token'));
    }
}


