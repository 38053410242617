import {Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, Renderer2} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {Router, ActivatedRoute} from '@angular/router';
import {EmpleadosService} from './componentes/empleados/services/empleados.service';
import {RutasImg} from '../../utiles/rutas';
import * as $ from 'jquery';

declare var $;

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.css']

})
export class AdminComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('siguiente') btNext: ElementRef;
    public fichados: any[] = [];
    public id: number;
    public fecha: string;
    public hoy: string;
    public fechas: any[] = [];
    public config: any;
    public alerta: number;
    public rutaimg: any;
    public empleados = false;
    public administracion = false;
    public numeroEmpresas: any[] = [];

    public fichadosSubscription = new Subscription();
    public configSubscription = new Subscription();
    public empresaSubscription = new Subscription();

    private empresas: any[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private _empleados: EmpleadosService,
        private renderer: Renderer2,
    ) {
        this.hoy = new Date().toISOString().substring(0, 10);
        this.alerta = 0;
        this.rutaimg = RutasImg;
        this._empleados.getUsers(2, this.alerta, this.fecha);
        this._empleados.getConfig();
        this.empresaSubscription = this._empleados.empresas$.subscribe(res => {
            this.empresas = res;
            this.getNumeroEmpresas();
        });
    }

    ngOnInit() {
        this.fichadosSubscription = this._empleados.fichados$.subscribe(res => {
            this.fichados = res;
            this.comprobarDepartamentos();
            this._empleados.getEmpresas();
        });
        this.configSubscription = this._empleados.config$.subscribe(res => {
            this.config = res;
        });
    }

    ngOnDestroy() {
        this.fichadosSubscription.unsubscribe();
        this.configSubscription.unsubscribe();
        this.empresaSubscription.unsubscribe();
    }

    ngAfterViewInit() {
    }

    empieza(fecha: string) {
        if (fecha.startsWith(this.hoy)) {
            return true;
        } else {
            return false;
        }
    }

    comprobarDepartamentos() {
        this.fichados.forEach(user => {
            if (user.id_role === '2') {
                this.empleados = true;
            }
            if (user.id_role === '3') {
                this.administracion = true;
            }
        })
    }

    getNumeroEmpresas() {
        this.fichados.forEach(user => {
            if (this.numeroEmpresas.findIndex(empresa => empresa.id === user.id_centro) === -1) {
                const empresa = {
                    id: user.id_centro,
                    nombreEmpresa: this.empresas[this.empresas.findIndex(empresa => empresa.id === parseInt(user.id_centro))].nombre
                };
                this.numeroEmpresas.push(empresa);
            }
        });
        $(document).ready(function () {
            $('.carousel').carousel({
                interval: 3500,
                ride: 'carousel',
                keyboard: true,
                pause: false
            });
        });
    }

    verUser(id) {
        this.router.navigate(['/admin/editempleado', id]);
    }
}
