<div class="card mb-3">
    <div class="card-header">
        <i class="fas fa-table"></i>
        Lista Departamentos
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-striped" id="dataTable" width="100%" cellspacing="0" data-page-length="10"
                   data-paging="true">
                <thead class="thead-oscuro">
                <tr>
                    <th>DEPARTAMENTO</th>
                    <th>EMPRESA</th>
                    <th>ACCIONES</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let departamento of departamentos; let i = index">
                    <td>{{departamento.departnombre | titlecase}} </td>
                    <td>{{departamento.centronombre | titlecase}} </td>
                    <td>
                        <button type="button" class="btn btn btn-outline-danger"
                                (click)="eliminarDepartamento(departamento.id)">
                            <i class="fas fa-trash"></i>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<!-- /.container-fluid -->
