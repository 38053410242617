import {Component, ViewChild, OnDestroy, OnInit, ViewEncapsulation, Output, EventEmitter} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Subscription} from 'rxjs/Subscription';
import {Datos} from '../../../../../../modelos/datos.modelo';
import {CrudempleadosService} from '../../services/crudempleados.service';
import {UploadService} from '../../../../../services/upload.service';
import {RutasImg} from '../../../../../../utiles/rutas';
import {MatSnackBar} from '@angular/material';
import {LoginService} from 'src/app/components/pages/services/login.service';

declare var $;

@Component({
    selector: 'app-datos',
    templateUrl: './datos.component.html',
    encapsulation: ViewEncapsulation.None
})
export class DatosComponent implements OnInit, OnDestroy {
    @ViewChild('datosForm') datosForm: NgForm;
    @Output() public emisor = new EventEmitter<any>();

    public provinciasSubscription: Subscription = null;
    public municipiosSubscription: Subscription = null;
    public empresasSubscription: Subscription = null;
    public departamentosSubscription: Subscription = null;
    public contratosSubscription: Subscription = null;

    public dato: Datos;
    public provincias: any[] = [];
    public municipios: any[] = [];
    public empresas: any[] = [];
    public departamentos: any[] = [];
    public contratos: any[] = [];
    public filesToUpload: Array<File>;
    public rutaimg: any;
    public imagen: any;
    public provincia: any;
    soloLectura = true;


    constructor(
        private _crud: CrudempleadosService,
        private _upload: UploadService,
        private snackBar: MatSnackBar,
        private _login: LoginService
    ) {
        if (this._login.getIdentity().role === 'ROLE_ADMIN') {
            this.soloLectura = false;
        }

        this.provincia = ""; // QUITAMOS EL TIPO NUMBER PARA VALIDAR EL FORM
        this.rutaimg = RutasImg;
        this.dato = {
            password: '',
            nombre: '',
            apellidos: '',
            direccion: '',
            nacimiento: null,
            dni: '',
            municipio: '',
            iban: '',
            username: '',
            id: null,
            id_municipio: 0,
            img: 'images/centro/persona_default.png',
            contactos: [],
            titulos: [],
            horarios: [],
            id_centro: "", // QUITAMOS EL TIPO NUMBER PARA VALIDAR EL FORM
            id_departamento: "", // QUITAMOS EL TIPO NUMBER PARA VALIDAR EL FORM
            pin: null,
            contrato: "",  // QUITAMOS EL TIPO NUMBER PARA VALIDAR EL FORM
            horas: 0,
        };

        this._crud.getProvincias();
        this._crud.getEmpresas();
        this._crud.getContratos();
    }

    ngOnInit() {
        this.provinciasSubscription = this._crud.provincias$.subscribe(res => {
            this.provincias = res;
        });

        this.municipiosSubscription = this._crud.municipios$.subscribe(res => {
            this.municipios = res;
        });

        this.empresasSubscription = this._crud.empresas$.subscribe(res => {
            this.empresas = res;
        });

        this.departamentosSubscription = this._crud.departamentos$.subscribe(res => {
            this.departamentos = res;
        });

        this.contratosSubscription = this._crud.contratos$.subscribe(res => {
            this.contratos = res;
        });

    }

    ngOnDestroy() {
        this.provinciasSubscription.unsubscribe();
        this.municipiosSubscription.unsubscribe();
        this.empresasSubscription.unsubscribe();
        this.departamentosSubscription.unsubscribe();
    }

    fileChangeEvent(fileinput: any) {
        this.filesToUpload = <Array<File>>fileinput.target.files;
        let isImagen = false;

        for (let i = 0; i < this.filesToUpload.length; i++) {
            if (this.filesToUpload[i].type.match('image.*')) {
                isImagen = true;
                continue;
            }
        }

        if (isImagen) {
            const file = this.filesToUpload[0];
            const reader = new FileReader();
            reader.onload = e => this.imagen = reader.result;
            reader.readAsDataURL(file);
            if (this.dato.id !== null) {
                this._upload.makeFileRequest(this.dato.id, this.filesToUpload, 'imagen', 'user').then((result: any) => {
                    if (result) {
                        const msg = 'Foto Subida Correctamente';
                        this.openSnackBar(msg, 'Cerrar');
                    }
                }).catch(error => {
                    // console.log(error);
                    const alerta = {
                        ttlModal: 'ERROR DE SUBIDA',
                        txtModal: error
                    };
                    this.emisor.emit(alerta);
                });
            }
        } else {
            const alerta = {
                ttlModal: 'Alerta',
                txtModal: 'Debe elegir un archivo de tipo imagen'
            };
            this.emisor.emit(alerta);
            this.imagen = null;
            this.filesToUpload = null;
        }
    }

    getMunicipios() {
        // console.log(this.provincia);
        this._crud.getMunicipios(this.provincia);
    }

    getDepartamentos() {
       // console.log(this.dato.id_centro);
        this._crud.getDepartamentos(this.dato.id_centro);
    }

    setMunicipio() {
        const id = Number(this.dato.id_municipio);
        const result = this.municipios.find(municipio => municipio.id === id);
        this.dato.municipio = result;
    }

    openSnackBar(message: string, action: string) {
        // console.log('OPEN SNACK BAR', message, action);
        this.snackBar.open(message, action, {
            duration: 2000,
        });
    }

    setHoras(ev) {
        const id = Number(ev.target.value);
        const contrato = this.contratos.find(contract => contract.contrato === id);
        this.dato.horas = contrato.horas;
    }


    onSubmit(datosForm: NgForm) {
/*        const datosFormulario = {
            nombre: datosForm.form.controls.nombre.valid,
            apellidos: datosForm.form.controls.apellidos.valid,
            dni: datosForm.form.controls.dni.valid,
            // nacimiento: datosForm.form.controls.nacimiento.valid,
            provincia: datosForm.form.value.provincia,  //VALOR(0)
            municipio: datosForm.form.controls.id_municipio.valid,
            direccion: datosForm.form.controls.direccion.valid,
            email: datosForm.form.controls.username.valid,
            empresa: datosForm.form.value.empresa, // VALOR(0)
            id_departamento: datosForm.form.controls.id_departamento.valid,
            // iban: datosForm.form.controls.iban.valid,
            pin: datosForm.form.controls.pin.valid,
            contrato: datosForm.form.value.contrato, // VALOR (0)
            horas: datosForm.form.value.horas // VALOR(0)
        };*/
    }

}
