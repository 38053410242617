<mat-horizontal-stepper linear="true" #stepper>
<!--    <mat-horizontal-stepper linear="true" #stepper>-->
        <mat-step label="Datos" [stepControl]="firstFormGroup">
            <div class="container mg-top-24">
                <app-datos (emisor)="dispararmodal($event)"></app-datos>
            </div>

        </mat-step>

        <mat-step label="Contacto" [stepControl]="secondFormGroup">
            <div class="container mg-top-24">
                <app-contacto (emisor)="dispararmodal($event)"></app-contacto>
            </div>
        </mat-step>

        <mat-step label="Horario" [stepControl]="thirdFormGroup">
            <div class="container mg-top-24">
                <app-horario (emisor)="dispararmodal($event)"></app-horario>
            </div>
        </mat-step>

        <mat-step label="Estudios" [stepControl]="fourthFormGroup">
            <div class="container mg-top-24">
                <app-estudios (emisor)="dispararmodal($event)"></app-estudios>

                                <button type="button" class="btn btn-dark float-right" [disabled]="fourthFormGroup.invalid"
                                        (click)="guardar()">Guardar
                                </button>
<!--                <button type="button" class="btn btn-dark float-right" (click)="guardar()">Guardar-->
<!--                                    </button>-->

            </div>
        </mat-step>

    </mat-horizontal-stepper>
    <app-my-modal [titulo]="ttlModal" [texto]="txtModal"></app-my-modal>
