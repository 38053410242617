import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';

import {
    MatTabsModule, MatStepperModule, MatFormFieldModule,
    MatIconModule, MatSnackBarModule, MatDatepickerModule,
    MatNativeDateModule, MatTableModule, MatPaginatorModule,
} from '@angular/material';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PipesModule} from '../../pipes.module';

/*** MAPA GOOGLE***/
import {AgmCoreModule} from '@agm/core';
import {MapId} from '../../utiles/Config';

// SERVICIOS
import {EmpleadosService} from './componentes/empleados/services/empleados.service';
import {CrudempleadosService} from './componentes/empleados/services/crudempleados.service';

// MODALES
import {MyModalComponent} from './modales/my-modal/my-modal.component';
import {PdfComponent} from './modales/pdf/pdf.component';

// COMPONENTES
import {AdminComponent} from './admin.component';
import {EmpleadosComponent} from './componentes/empleados/empleadoList/empleados.component';
import {AddempleadoComponent} from './componentes/empleados/empleadoAdd/addempleado.component';
import {SeguimientoComponent} from './componentes/empleados/seguimientoList/seguimiento.component';
import {DatosComponent} from './componentes/empleados/componentes/datos/datos.component';
import {ContactoComponent} from './componentes/empleados/componentes/contacto/contacto.component';
import {EstudiosComponent} from './componentes/empleados/componentes/estudios/estudios.component';
import {EditempleadoComponent} from './componentes/empleados/empleadoEdit/editempleado.component';
import {HorarioComponent} from './componentes/empleados/componentes/horario/horario.component';
import {ControlComponent} from './componentes/empleados/componentes/control/control.component';
import {EmpresaaddComponent} from './componentes/empresa/empresaAdd/empresaadd.component';
import {DepartamentoaaddComponent} from './componentes/departamento/departamentoAdd/departamentoaadd.component';
import {NgxPaginationModule} from "ngx-pagination";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {EmpresaLocalizacionComponent} from './componentes/empresa/empresa-localizacion/empresa-localizacion.component';
import {EmpresaLocalizacionMapComponent} from './componentes/empresa/empresa-localizacion-map/empresa-localizacion-map.component';
import {CdkStepperModule} from "@angular/cdk/stepper";


@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        AgmCoreModule.forRoot({
            apiKey: MapId,
            libraries: ['places']
        }),
        MatTabsModule,
        MatStepperModule,
        MatFormFieldModule,
        MatIconModule,
        MatSnackBarModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTableModule,
        MatPaginatorModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        NgxPaginationModule,
        NgbModule,
        MatStepperModule,
        CdkStepperModule
    ],
    declarations: [
        MyModalComponent,
        PdfComponent,
        AdminComponent,
        EmpleadosComponent,
        AddempleadoComponent,
        SeguimientoComponent,
        DatosComponent,
        ContactoComponent,
        EstudiosComponent,
        EditempleadoComponent,
        HorarioComponent,
        ControlComponent,
        EmpresaaddComponent,
        DepartamentoaaddComponent,
        EmpresaLocalizacionComponent,
        EmpresaLocalizacionMapComponent,
    ],
    providers: [
        EmpleadosService,
        CrudempleadosService,
        DatePipe
    ],

    exports: [
        AdminComponent,
        EmpleadosComponent,
        AddempleadoComponent,
        SeguimientoComponent,
        DatosComponent,
        ContactoComponent,
        EstudiosComponent,
        EditempleadoComponent,
        HorarioComponent,
        ControlComponent,
        EmpresaLocalizacionComponent,
    ]

})
export class AdminModule {
}
