<ul class="sidebar navbar-nav toggled">
    <ng-container *ngFor="let link of links; let i = index">

        <li *ngIf="link.submenu.length === 0 else submenuside" class="nav-item">
            <a class="nav-link" [routerLink]="link.path">
                <i [ngClass]="[link.icon]"></i>
                <span>{{link.title}}</span>
            </a>
        </li>

        <ng-template #submenuside>
            <li id="desplegable{{i}}" class="nav-item dropdown" (click)="desplegarsubmenu(i)" (clickOutside)="close($event)">
                <a class="nav-link dropdown-toggle" id="pagesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <i [ngClass]="[link.icon]"></i>
                    <span>{{link.title}}</span>
                </a>
                <div  id="listadesplegada{{i}}" class="dropdown-menu" aria-labelledby="pagesDropdown" (clickOutside)="close($event)">
                    <a *ngFor="let link of link.submenu" class="dropdown-item" [routerLink]="link.path">
                            <i [ngClass]="[link.icon]"></i>
                            <span>{{link.title}}</span>
                    </a>
                </div>
            </li>
        </ng-template>

    </ng-container>



</ul>

