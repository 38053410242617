import {Component, OnInit, OnDestroy, Renderer2} from '@angular/core';
import {Router} from '@angular/router';
import {LoginService} from '../../pages/services/login.service';
import {SharedComponent} from '../shared.component';

// declare const $: any;

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {

    role = '';

    constructor(private router: Router, private _login: LoginService, private shared: SharedComponent, private renderer: Renderer2) {

        this.role = this._login.getIdentity().role;
    }

    ngOnInit() {
    }

    ngOnDestroy() {

    }

    desplegarSideBar() {
        this.shared.desplegar();
    }

    desplegarsubmenu() {
        const elemento = document.getElementById('desplegable');
        const submenu = document.getElementById('listadesplegada');
        elemento.classList.toggle('show');
        submenu.classList.toggle('show');
        this.isDropdownOpen(elemento, submenu);
    }

    isDropdownOpen(elemento, submenu) {
        // funcion para cualquier clic en el documento
        const rend = this.renderer;
        document.addEventListener('click', function (e) {
            // obtiendo informacion del DOM para
            const clic = e.target;
            // tslint:disable-next-line:max-line-length
            if (elemento.classList.contains('show') && clic !== elemento && clic !== elemento.firstChild && clic !== elemento.firstChild.firstChild) {
                rend.removeClass(elemento, 'show');
                rend.removeClass(submenu, 'show');
            }
        }, false);
    }

    logout() {
        this._login.logout();
    }

}
