import { Component, ViewChild, OnDestroy, OnInit, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { Datos } from '../../../../../../modelos/datos.modelo';
import { CrudempleadosService } from '../../services/crudempleados.service';
import {LoginService} from "../../../../../pages/services/login.service";

declare var $;

@Component({
  selector: 'app-estudios',
  templateUrl: './estudios.component.html',
  encapsulation: ViewEncapsulation.None
})
export class EstudiosComponent implements OnInit, OnDestroy {
  @ViewChild('estudiosForm') estudiosForm: NgForm;
  @Output() public emisor = new EventEmitter<any>();
  public fantasma: any;
  public dato: Datos;
    role = '';

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _crud: CrudempleadosService,
    private _login: LoginService
  ) {
    this.role = this._login.getIdentity().role;
    this.dato = {
      password: '',
      nombre: '',
      apellidos: '',
      direccion: '',
      nacimiento: null,
      dni: '',
      municipio: '',
      iban: '',
      username: '',
      id: null,
      id_municipio: 0,
      img: '',
      contactos: [],
      titulos: [],
      horarios: [],
      id_centro: 0,
      id_departamento: 0,
      pin: 0,
      contrato: 0,
      horas: 0,
    };

  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  open(id) {
    const elemento = $('#' + id);

    if (elemento[0].validity.valid) {
      if (this.dato.id === null) {
        const item = {
          titulo: elemento.val(),
        };
        this.dato.titulos.push(item);
        this.fantasma = 'lleno';
        elemento.val('');
        this.controlPaso();
      } else {
        const item = {
          id: this.dato.id,
          titulo: elemento.val(),
        };
        this._crud.addTitulo(item);
        elemento.val('');
      }
    } else {
      elemento[0].classList.toggle('ng-invalid');
      elemento[0].classList.toggle('ng-pristine');
      elemento[0].classList.toggle('ng-touched');
      const alerta = {
        ttlModal: 'Alerta',
        txtModal: 'Revise los campos en rojo'
      };
      this.emisor.emit(alerta);
    }
  }

  controlValido(id) {
    const elemento = $('#' + id);
    if (!elemento[0].validity.valid) {
      if (!elemento[0].classList.contains('ng-invalid')) {
        elemento[0].classList.add('ng-invalid');
        elemento[0].classList.add('ng-pristine');
        elemento[0].classList.add('ng-touched');
      }
    } else {
      if (elemento[0].classList.contains('ng-invalid')) {
        elemento[0].classList.toggle('ng-invalid');
        elemento[0].classList.toggle('ng-pristine');
        elemento[0].classList.toggle('ng-touched');
      }
    }
  }

  borrar(i) {
    if (this.dato.id === null) {
      this.dato.titulos.splice(i, 1);
      this.controlPaso();
    } else {
      const idt = this.dato.titulos[i].id;
      this._crud.removeTitulo(this.dato.id, idt);
      this.controlPaso();
    }
  }

  controlPaso() {
    if (this.dato.titulos.length === 0) {
      this.fantasma = undefined;
      const alerta = {
        ttlModal: 'Alerta',
        txtModal: 'Al menos debe introducir una titulación'
      };
      this.emisor.emit(alerta);
    }
  }

}
