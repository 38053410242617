
<div class="row">

    <div class="col-lg-2 col-md-2 col-sm-12" *ngIf="dato">
        <div class="imagen"
             [ngStyle]="{'background-image':(imagen && imagen !== '') ? 'url(' + imagen  + ')' : 'url(' + rutaimg + dato.img + ')'}"
             onclick="document.getElementById('buscadorPersona').click()"></div>

        <input id="buscadorPersona" name="filebutton-normal" type="file" class="file" (change)="fileChangeEvent($event)"
               hidden [disabled]="soloLectura">
    </div>

    <div class="col-lg-10 col-md-10 col-sm-12">
        <!--      <form ngNativeValidate class="form form-horizontal" novalidate="" (ngSubmit)="onSubmit(datosForm)" #datosForm="ngForm" >-->
        <form ngNativeValidate class="form form-horizontal" (ngSubmit)="onSubmit(datosForm)" #datosForm="ngForm">

            <div class="form-group col-md-6 col-sm-12 floatL" style="padding-button: 0px">
                <label>NOMBRE(*)</label>
                <input class="form-control" type="text" placeholder="Nombre" name="nombre" minlength="2"
                       [(ngModel)]="dato.nombre" required [readonly]="soloLectura" oninvalid="setCustomValidity('Su Nombre es Obligatorio')"
                       oninput="setCustomValidity('')">
            </div>

            <div class="form-group col-md-6 col-sm-12 floatL">
                <label>APELLIDOS(*)</label>
                <input class="form-control" type="text" placeholder="Apellidos" name="apellidos" minlength="2"
                       [(ngModel)]="dato.apellidos" required [readonly]="soloLectura" oninvalid="setCustomValidity('Debe indicar al menos su primer apellido')"
                       oninput="setCustomValidity('')">
            </div>

            <div class="form-group col-md-6 col-sm-12 floatL">
                <label>DNI/NIE(*)</label>
                <input class="form-control" type="text" placeholder="DNI/NIE" name="dni" [(ngModel)]="dato.dni"
                       pattern="^[0-9]{8}([-]?)[a-zA-Z]$|^[xyzXYZ]([-]?)[0-9]{7}([-]?)[a-zA-Z]$"
                       required [readonly]="soloLectura" oninvalid="setCustomValidity('Introduzca un DNI o NIE válido')"
                       oninput="setCustomValidity('')">
            </div>

            <div class="form-group col-md-6 col-sm-12 floatL">
                <label>Fecha Nacimiento</label>
                <input class="form-control" type="date" name="nacimiento" [(ngModel)]="dato.nacimiento"
                       [readonly]="soloLectura">
            </div>

            <div class="form-group col-md-6 col-sm-12 floatL">
                <label>PROVINCIA(*)</label>
                <select [(ngModel)]="provincia" name="provincia" class="form-control" (change)="getMunicipios()"
                        [disabled]="soloLectura" oninvalid="setCustomValidity('Debe Indicar su Provincia')"
                        oninput="setCustomValidity('')" required>
                    <ng-container *ngIf="provincias.length > 0">
<!--                        <option selected value="0">Elige Provincia</option>-->
                        <option value="">Elige Provincia</option>
                        <option *ngFor="let provincia of provincias"
                                [value]="provincia.id">{{provincia.provincia}}</option>
                    </ng-container>
                </select>
            </div>

            <div class="form-group col-md-6 col-sm-12 floatL">
                <label>MUNICIPIO(*)</label>
                <!--        <select [(ngModel)]="dato.id_municipio" name="id_municipio" class="form-control" (change)="setMunicipio()" [disabled]="!municipios.length > 0 || soloLectura">-->
                <select [(ngModel)]="dato.id_municipio" name="id_municipio" class="form-control"
                        (change)="setMunicipio()" [disabled]="municipios.length == 0 || soloLectura">
                    <option selected value="0">Elige Municipio</option>
                    <option *ngFor="let municipio of municipios" [value]="municipio.id">{{municipio.municipio}}</option>
                </select>
            </div>

            <div class="form-group col-md-6 col-sm-12 floatL">
                <label>DIRECCIÓN(*)</label>
                <input class="form-control" type="text" placeholder="Dirección" name="direccion"
                       [(ngModel)]="dato.direccion" required [readonly]="soloLectura">
            </div>

            <div class="form-group col-md-6 col-sm-12 floatL">
                <label>EMAIL(*)</label>
                <input class="form-control" type="email" placeholder="Email" name="username" [(ngModel)]="dato.username"
                       pattern="^[a-zA-Z0-9_\-\.~]{2,}@[a-zA-Z0-9_\-\.~]{2,}\.[a-zA-Z]{2,4}$"
                       required [readonly]="soloLectura" oninvalid="setCustomValidity('Introduzca un email válido')"
                       oninput="setCustomValidity('')">
            </div>

            <div class="form-group col-md-6 col-sm-12 floatL">
                <label>EMPRESA(*)</label>
                <!--                <select [(ngModel)]="dato.id_centro" name="empresa" class="form-control" (change)="getDepartamentos()"-->
                <!--                        [disabled]="soloLectura" required>-->
                <select [(ngModel)]="dato.id_centro" name="empresa" class="form-control" (change)="getDepartamentos()"
                        [disabled]="soloLectura" oninvalid="setCustomValidity('Debe Seleccionar a que Empresa Pertenece')"
                        oninput="setCustomValidity('')" required>
                    <ng-container *ngIf="empresas.length > 0">

                        <!--            <option selected value="0">Elige Empresa</option>-->
                                                <option value="">Elige una Empresa</option>
                                                <option *ngFor="let empresa of empresas" [value]="empresa.id">{{empresa.nombre}}</option>

                    </ng-container>
                </select>
            </div>

            <div class="form-group col-md-6 col-sm-12 floatL">
                <label>DEPARTAMENTO(*)</label>
<!--                        <select [(ngModel)]="dato.id_departamento" name="id_departamento" class="form-control" [disabled]="!departamentos.length > 0 || soloLectura" (change)="hector()" required>-->
                            <select [(ngModel)]="dato.id_departamento" name="id_departamento" class="form-control" [disabled]="departamentos.length == 0 || soloLectura"  required>
<!--                    <option selected value="0">Elige Departamento</option>-->
                    <option value="">Elige Departamento</option>
                    <option *ngFor="let departamento of departamentos"
                            [value]="departamento.id">{{departamento.departnombre}}</option>
                </select>
            </div>

            <div class="form-group col-md-6 col-sm-12 floatL">
                <label>CONTRASEÑA</label>
                <input class="form-control" type="text" placeholder="Contraseña" name="password" [(ngModel)]="dato.password"
                       [readonly]="soloLectura">
            </div>

            <div class="form-group col-md-6 col-sm-12 floatL">
                <label>PIN(*)</label>
                <input class="form-control" type="number" placeholder="Pin" name="pin" [(ngModel)]="dato.pin"
                       [readonly]="soloLectura" oninvalid="setCustomValidity('Debe indicar un PIN para que su trabador pueda fichar')"
                       oninput="setCustomValidity('')">
            </div>

            <div class="form-group col-md-6 col-sm-12 floatL">
                <label>JORNADA LABORAL(*)</label>
                <!--        <select [(ngModel)]="dato.contrato" name="contrato" class="form-control" (change)="setHoras($event)" [disabled]="!contratos.length > 0 || soloLectura">-->
                <select [(ngModel)]="dato.contrato" name="contrato" class="form-control" (change)="setHoras($event)"
                        [disabled]="contratos.length <= 0 || soloLectura" required oninvalid="setCustomValidity('Debe Indicar la Jornada Laboral de su Trabajador')"
                        oninput="setCustomValidity('')">

<!--                    <option selected value="0">Elige Tipo Contrato</option>-->
                    <option value="">Elige Tipo Contrato</option>
                    <option *ngFor="let contrato of contratos; let i = index;"
                            [value]="contrato.contrato">{{contrato.nombre}}</option>
                </select>
            </div>

            <div class="form-group col-md-6 col-sm-12 floatL">
                <label>HORAS(*)</label>
                <input class="form-control" type="number" placeholder="Horas Semanales" name="horas"
                       [(ngModel)]="dato.horas" readonly required>
            </div>

            <div class="row">
                <div class="col-sm-12 text-center mt-3">
                    <button type="submit" mat-button matStepperNext class="btn btn-dark btn-sm center-block"><i
                            class="fas fa-arrow-alt-circle-right"></i> Siguiente
                    </button>
                </div>
            </div>
        </form>
    </div>

</div>



<!--NUEVO-->
<!--<div class="row">

    <div class="col-lg-2 col-md-2 col-sm-12" *ngIf="dato">
        <div class="imagen" [ngStyle]="{'background-image':(imagen && imagen !== '') ? 'url(' + imagen  + ')' : 'url(' + rutaimg + dato.img + ')'}"
             onclick="document.getElementById('buscadorPersona').click()"></div>

        <input id="buscadorPersona" name="filebutton-normal" type="file" class="file" (change)="fileChangeEvent($event)" hidden [disabled]="soloLectura">
    </div>

    <div class="col-lg-10 col-md-10 col-sm-12">
        <form class="form form-horizontal" novalidate="" (ngSubmit)="onSubmit()" #datosForm="ngForm" >

            <div class="form-group col-md-6 col-sm-12 floatL">
                <input class="form-control" type="text" placeholder="Nombre" name="nombre" [(ngModel)]="dato.nombre" required [readonly]="soloLectura">
            </div>

            <div class="form-group col-md-6 col-sm-12 floatL">
                <input class="form-control" type="text" placeholder="Apellidos" name="apellidos" [(ngModel)]="dato.apellidos" required [readonly]="soloLectura">
            </div>

            <div class="form-group col-md-6 col-sm-12 floatL">
                <input class="form-control" type="text" placeholder="DNI/NIE" name="dni" [(ngModel)]="dato.dni" pattern="^[0-9]{8}([-]?)[a-zA-Z]$|^[xyzXYZ]([-]?)[0-9]{7}([-]?)[a-zA-Z]$"
                       required [readonly]="soloLectura">
            </div>

            <div class="form-group col-md-6 col-sm-12 floatL">
                <input class="form-control" type="date" name="nacimiento" [(ngModel)]="dato.nacimiento" required [readonly]="soloLectura">
            </div>

            <div class="form-group col-md-6 col-sm-12 floatL">
                <select [(ngModel)]="provincia" name="provincia" class="form-control" (change)="getMunicipios()" [disabled]="soloLectura">
                    <ng-container *ngIf="provincias.length > 0">
                        <option selected value="0">Elige Provincia</option>
                        <option *ngFor="let provincia of provincias" [value]="provincia.id">{{provincia.provincia}}</option>
                    </ng-container>
                </select>
            </div>

            <div class="form-group col-md-6 col-sm-12 floatL">
                <select [(ngModel)]="dato.id_municipio" name="id_municipio" class="form-control" (change)="setMunicipio()" [disabled]="!municipios.length > 0 || soloLectura">
                    <option selected value="0">Elige Municipio</option>
                    <option *ngFor="let municipio of municipios" [value]="municipio.id">{{municipio.municipio}}</option>
                </select>
            </div>

            <div class="form-group col-md-6 col-sm-12 floatL">
                <input class="form-control" type="text" placeholder="Dirección" name="direccion" [(ngModel)]="dato.direccion" required [readonly]="soloLectura">
            </div>

            <div class="form-group col-md-6 col-sm-12 floatL">
                <input class="form-control" type="email" placeholder="Email" name="username" [(ngModel)]="dato.username" pattern="^[a-zA-Z0-9_\-\.~]{2,}@[a-zA-Z0-9_\-\.~]{2,}\.[a-zA-Z]{2,4}$"
                       required [readonly]="soloLectura">
            </div>

            <div class="form-group col-md-6 col-sm-12 floatL">
                <select [(ngModel)]="dato.id_centro" name="empresa" class="form-control" (change)="getDepartamentos()" [disabled]="soloLectura">
                    <ng-container *ngIf="empresas.length > 0">
                        <option selected value="0">Elige Empresa</option>
                        <option *ngFor="let empresa of empresas" [value]="empresa.id">{{empresa.nombre}}</option>
                    </ng-container>
                </select>
            </div>

            <div class="form-group col-md-6 col-sm-12 floatL">
                <select [(ngModel)]="dato.id_departamento" name="id_departamento" class="form-control" [disabled]="!departamentos.length > 0 || soloLectura" >
                    <option selected value="0">Elige Departamento</option>
                    <option *ngFor="let departamento of departamentos" [value]="departamento.id">{{departamento.departnombre}}</option>
                </select>
            </div>

            <div class="form-group col-md-6 col-sm-12 floatL">
                <input class="form-control" type="text" placeholder="IBAN" name="iban" [(ngModel)]="dato.iban" pattern="^([A-Z]{2}[0-9]{2})([ \t|\-])?([0-9]{4})([ \t|\-])?([0-9]{4})([ \t|\-])?([0-9]{2})([ \t|\-])?([0-9]{10})$"
                       required [readonly]="soloLectura">
            </div>

            <div class="form-group col-md-6 col-sm-12 floatL">
                <input class="form-control" type="number" placeholder="Pin" name="pin" [(ngModel)]="dato.pin" required [readonly]="soloLectura">
            </div>

            <div class="form-group col-md-6 col-sm-12 floatL">
                <select [(ngModel)]="dato.contrato" name="contrato" class="form-control" (change)="setHoras($event)" [disabled]="!contratos.length > 0 || soloLectura">
                    <option selected value="0">Elige Tipo Contrato</option>
                    <option *ngFor="let contrato of contratos; let i = index;" [value]="contrato.contrato">{{contrato.nombre}}</option>
                </select>
            </div>

            <div class="form-group col-md-6 col-sm-12 floatL">
                <input class="form-control" type="number" placeholder="Horas Semanales" name="horas" [(ngModel)]="dato.horas" readonly required>
            </div>

        </form>
    </div>

</div>-->
