<div class="background">
    <div class="numKeyboard" (click)="clickNumero($event)">
        <div class="id col-6 mx-auto" *ngIf="!estado_empleado">
            <input type="password" oninput="this.value=this.value.replace(/(?![0-9])./gmi,'')" class="form-control"
                   id="codigoEmpleado" placeholder="ID"
                   [(ngModel)]="id_empleado" (keypress)="onKeydown($event)">
        </div>
        <div class="id col-6 mx-auto" *ngIf="estado_empleado">
            <input type="password" oninput="this.value=this.value.replace(/(?![0-9])./gmi,'')" class="form-control"
                   id="pinEmpleado" placeholder="PIN"
                   [(ngModel)]="pin_empleado" (keypress)="onKeydown($event)">
        </div>
        <div class="row">
            <div class="col-4">
                <button type="button" id="1" class="btn btn-secondary">1</button>
            </div>
            <div class="col-4">
                <button type="button" id="2" class="btn btn-secondary">2</button>
            </div>
            <div class="col-4">
                <button type="button" id="3" class="btn btn-secondary">3</button>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <button type="button" id="4" class="btn btn-secondary">4</button>
            </div>
            <div class="col-4">
                <button type="button" id="5" class="btn btn-secondary">5</button>
            </div>
            <div class="col-4">
                <button type="button" id="6" class="btn btn-secondary">6</button>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <button type="button" id="7" class="btn btn-secondary">7</button>
            </div>
            <div class="col-4">
                <button type="button" id="8" class="btn btn-secondary">8</button>
            </div>
            <div class="col-4">
                <button type="button" id="9" class="btn btn-secondary">9</button>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <button type="button" class="btn btn-warning" (click)="borrar()">
                    <i class="fas fa-backspace"></i>
                </button>
            </div>
            <div class="col-4">
                <button type="button" id="0" class="btn btn-secondary">0</button>
            </div>
            <div class="col-4">
                <button *ngIf="estado_empleado" type="button" class="btn btn-danger" (click)="reset()">
                    <span class="texto">RESET</span>
                    <i class="fas fa-recycle"></i>
                </button>
            </div>
        </div>
        <div class="row" *ngIf="id_empleado.length > 0 && !estado_empleado">
            <div class="col-xl-6">
                <button type="button" class="btn btn-secondary" (click)="entrada()">
                    <span class="texto">ENTRADA </span>
                    <i class="fas fa-sign-in-alt"></i>
                </button>
            </div>
            <div class="col-xl-6">
                <button type="button" class="btn btn-secondary" (click)="salida()">
                    <span class="texto">SALIDA </span>
                    <i class="fas fa-sign-out-alt"></i>
                </button>
            </div>
        </div>
        <div class="row" *ngIf="pin_empleado.length > 0 && estado_empleado">
            <div class="col-12">
                <button type="button" class="btn btn-success"
                        (click)="fichar()">
                    <div class="texto">FICHAR</div>
                    <i class="fas fa-check-circle"></i>
                </button>
            </div>
        </div>
    </div>
</div>
