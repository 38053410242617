import {Component, OnInit} from '@angular/core';
import {DatosDepartamentos} from '../../../../../modelos/datos.modelo';
import {Subscription} from "rxjs";
import {CrudempleadosService} from "../../empleados/services/crudempleados.service";
import { Router} from '@angular/router';

@Component({
    selector: 'app-departamentoaadd',
    templateUrl: './departamentoaadd.component.html',
    styleUrls: ['./departamentoaadd.component.css']
})
export class DepartamentoaaddComponent implements OnInit {

    public empresasSubscription: Subscription = null;

    public dato: DatosDepartamentos;
    public empresas: any[] = [];
    public nombre = '';
    public id_centro = 0;


    constructor(private _crud: CrudempleadosService,
                private router: Router) {
        this._crud.getEmpresas();
    }

    ngOnInit() {
        this.empresasSubscription = this._crud.empresas$.subscribe(res => {
            this.empresas = res;
        });
    }

    ngOnDestroy() {
        this.empresasSubscription.unsubscribe();
    }

    guardarEmpresa() {
      let departamento = {
        nombre: this.nombre,
        id_centro: this.id_centro
      };

      this._crud.addDepartamento(departamento);
      // this.router.navigateByUrl('admin/departamento');

    }

}
