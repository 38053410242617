<div class="card">
    <h5 class="card-header">Datos Departamento</h5>
    <div class="card-body">
        <form class="form form-horizontal" novalidate="" #datosForm="ngForm">

            <div class="row pl-3 pr-3 mt-3">
                <div class="form-group col-md-6 col-sm-6 floatL">
                    <input class="form-control" type="text" placeholder="Departamento" name="nombre"
                           [(ngModel)]="nombre"
                           required>
                </div>

                <div class="form-group col-md-6 col-sm-6 floatL">
                    <select [(ngModel)]="id_centro" name="departamento" class="form-control">
                        <ng-container *ngIf="empresas.length > 0">
                            <option selected value="0">Elige Empresa</option>
                            <option *ngFor="let empresa of empresas"
                                    [value]="empresa.id">{{empresa.nombre}}</option>
                        </ng-container>
                    </select>
                </div>
            </div>
            <button type="button" class="btn btn-outline-dark float-right mr-3" (click)="guardarEmpresa()">Guardar</button>
        </form>
    </div>
</div>
