// src/app/auth/auth-guard.service.ts
import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRoute,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { LoginService } from '../pages/services/login.service';
import { Observable } from 'rxjs/Observable';



@Injectable()
export class AuthGuardService implements CanActivate {
  private auth: boolean;
  constructor(
    private router: Router,
    private _login: LoginService,
    private route: ActivatedRoute
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // console.log('RUTA: ', state.url);
    return this._login.authGuardAdmin().then(res => {
      if (!res) {
        return this._login.authGuardMaestro().then( res => {
          if (!res){
            this.router.navigate(['/public/login'], { relativeTo: this.route });
            return false;
          } else {
            const urlSplitted = state.url.split('/');
            // console.log('USER AUTENTICADO: ', this._login.getIdentity().id);
            if (urlSplitted[2] === 'editempleado' && this.comprobarMismoUsuario(urlSplitted[urlSplitted.length - 1])) {
              return true;
            } else {
              return false;
            }
          }
        });
        // this.router.navigate(['/public/login'], { relativeTo: this.route });
        // return false;
      } else {
        return true;
      }
    });
  }

  comprobarMismoUsuario(urlUserId: any) {
    if (this._login.getIdentity().id + '' === urlUserId) {
      return true;
    } else {
      return false;
    }
  }
}
