import {Component, OnDestroy, OnInit} from '@angular/core';
import {DepartamentoServiceService} from "../services/departamento-service.service";
import {Subscription} from 'rxjs/Subscription';
import swal from "sweetalert";

@Component({
    selector: 'app-departamento',
    templateUrl: './departamento.component.html',
    styleUrls: ['./departamento.component.css']
})
export class DepartamentoComponent implements OnInit, OnDestroy {

    private departamentoSubscription: Subscription = null;
    departamentos: any[] = [];

    constructor(public  _departamento: DepartamentoServiceService) {
    }

    ngOnInit() {
        this.departamentoSubscription = this._departamento.departamentos$.subscribe(res => {
            this.departamentos = res;
        });

        this._departamento.getDepartamentos();
    }

    ngOnDestroy() {
        this.departamentoSubscription.unsubscribe();
    }

    eliminarDepartamento(id: number) {
        const nombreDepartamento = this.departamentos[this.departamentos.findIndex(departamento => departamento.id === id)].departnombre;
        // @ts-ignore
        swal({
            title: 'BORRAR',
            text: '¿Seguro que quiere borrar ' + nombreDepartamento + '?',
            icon: 'error',
            buttons: true,
            dangerMode: true
        }).then((willDelete) => {
            if (willDelete) {
                this._departamento.removeDepartamento(id).subscribe(res => {
                    if (res['code'] === 200) {
                        swal('Se ha eliminado ' + nombreDepartamento + '.', {
                            icon: 'success',
                            timer: 3000
                        });
                        this.departamentos.splice(this.departamentos.findIndex(departamento => departamento.id === res['id']), 1);
                    } else {
                        swal({
                            title: "ERROR",
                            text: "Existen usuarios asignados en este departamento.",
                            icon: "error",
                            timer: 3000
                        });
                    }
                }, error => {
                    // console.log(error);
                    return error;
                });
            }
        });
    }
}
