<nav class="navbar navbar-expand navbar-dark bg-dark static-top">

    <!-- <button #btnavbar id="sidebarToggle" class="btn btn-link btn-sm text-white order-1 order-sm-0"> -->
    <button #btnavbar id="sidebarToggle" class="btn btn-link btn-sm text-white order-1 order-sm-0"
            *ngIf="role === 'ROLE_ADMIN'" (click)="desplegarSideBar()">
        <i class="fas fa-bars"></i>
    </button>
    <a class="navbar-brand mr-1" href="/#/admin/admin">Ubik-Me</a>


    <ul class="navbar-nav ml-auto ml-md-0">
        <li id="desplegable" class="nav-item dropdown no-arrow" (click)="desplegarsubmenu()">
            <a class="nav-link dropdown-toggle" id="userDropdown" role="button" data-toggle="dropdown"
               aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-user-circle fa-fw"></i>
            </a>
            <div id="listadesplegada" class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
                <!-- <a class="dropdown-item" href="#">Settings</a>
                <a class="dropdown-item" href="#">Activity Log</a> -->
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" (click)="logout()">
                    <i class="fas fa-sign-out-alt"></i>
                    Logout
                </a>
            </div>
        </li>
    </ul>
</nav>
