import { Component, OnInit} from '@angular/core';
import {CrudempleadosService} from "../../empleados/services/crudempleados.service";
import {DatosEmpresa} from "../../../../../modelos/datos.modelo";
import {Subscription} from 'rxjs/Subscription';
import {Router} from '@angular/router';
import {EmpresaService} from "../services/empresa.service";
import swal from "sweetalert";

@Component({
    selector: 'app-empresaadd',
    templateUrl: './empresaadd.component.html',
    styleUrls: ['./empresaadd.component.css']
})
export class EmpresaaddComponent implements OnInit {

    public provinciasSubscription: Subscription = null;
    public municipiosSubscription: Subscription = null;

    public dato: DatosEmpresa;
    public id_provincia = 0;
    public id_municipio = 0;


    public provincias: any[] = [];
    public municipios: any[] = [];
    public nombre = '';
    public cif = '';
    public longitud = '';
    public latitud = '';
    public direccion = '';
    public radio = '';


    constructor(private _crud: CrudempleadosService,
                private router: Router,
                private empresaService: EmpresaService) {
        this._crud.getEmpresas();
        this._crud.getProvincias();
    }

    ngOnInit() {

        this.provinciasSubscription = this._crud.provincias$.subscribe(res => {
            this.provincias = res;
        });

        this.municipiosSubscription = this._crud.municipios$.subscribe(res => {
            this.municipios = res;
        });

    }


    ngOnDestroy() {
        this.provinciasSubscription.unsubscribe();
        this.municipiosSubscription.unsubscribe();
    }


    crearEmpresa() {
        if(this.id_provincia == 0 || this.id_municipio == 0  ) {
            window.alert('Debe Seleccionar Provincia y Municipio');
        } else {
            let createEmpresaMap = {
                cif: this.cif,
                nombre: this.nombre,
                direccion: this.direccion,
                id_provincia: this.id_provincia,
                id_municipio: this.id_municipio,
                longitud: this.longitud,
                latitud: this.latitud,
                radio: this.radio
            };

           this.empresaService.addEmpresaMap(createEmpresaMap).subscribe( res => {
                swal(`¡ La empresa ${this.nombre} se ha creado correctamente !`, {
                    icon: 'success',
                    timer: 3000
                }).then(r => r);
                this.router.navigate(['/admin/empresa']).then(r => r);
            });
        }
    }

    getMunicipios() {
        this._crud.getMunicipios(this.id_provincia);
    }
}
