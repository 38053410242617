import {Component, OnInit, OnDestroy, ViewChild, EventEmitter, Output} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {EmpleadosService} from '../../services/empleados.service';
import {MatPaginator} from '@angular/material/paginator';
import {Datos} from '../../../../../../modelos/datos.modelo';
import {LoginService} from "../../../../../pages/services/login.service";

import swal from "sweetalert";

export interface Fichaje {
    fecha: Date;
    entrada: Date;
    salida: Date;
    horas: number;
}

@Component({
    selector: 'app-control',
    templateUrl: './control.component.html'
})
export class ControlComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @Output() public pdfemisor = new EventEmitter<any>();
    public fichajes: any[] = [];
    public id: number;
    public anos: any[] = [];
    public meses: any[] = [];
    public dias: any[] = [];
    public entrefechas: any[] = [];
    public config: any;
    public alerta: number;
    public ano: string;
    public mes: string;
    public dia: string;
    public nombre: string;
    public inicio: Date;
    public fin: Date;
    public dato: Datos;
    public arreglados: any[] = [];

    public fichajesSubscription = new Subscription();
    public configSubscription = new Subscription();
    public anosSubscription = new Subscription();
    public mesesSubscription = new Subscription();
    public disSubscription = new Subscription();
    public entrefechasSubscription = new Subscription();

    actualPage = 1;
    role: string;
    editarMode = false;
    private hastaFecha = false;
    private clonadoUltimaPosicion: any;
    timeEntradaAdd = {
        hour: 8,
        minute: 0
    };
    timeSalidaAdd = {
        hour: 9,
        minute: 0
    };
    fechaAdd: any;

    constructor(private _empleados: EmpleadosService,
                private _login: LoginService) {
        this.role = this._login.getIdentity().role;
        this.alerta = 0;
        this._empleados.getConfig();
    }

    ngOnInit() {
        this.ano = '';
        this.mes = '';
        this.dia = '';

        this.fichajesSubscription = this._empleados.fichajes$.subscribe(res => {
            this.fichajes = res;
            this.montarArrayTablaFichajes();
        });
        this.configSubscription = this._empleados.config$.subscribe(res => {
            this.config = res;
        });

        this.anosSubscription = this._empleados.anos$.subscribe(res => {
            this.anos = res;
        });

        this.mesesSubscription = this._empleados.meses$.subscribe(res => {
            this.meses = res;
        });

        this.disSubscription = this._empleados.dias$.subscribe(res => {
            this.dias = res;
        });

        this.entrefechasSubscription = this._empleados.entrefechas$.subscribe(res => {
            if (res) {
                this.entrefechas = res;
                // this.montadoarray(res);
            }
            // this.filtradoFichajes(); // FUNCION POR TERMINAR Y DETERMINAR LAS CONDICIONALES DE ELIMINAR O ACTUALIZAR FICHAJES
        });
    }

    ngOnDestroy() {
        this.fichajesSubscription.unsubscribe();
        this.configSubscription.unsubscribe();
        this.anosSubscription.unsubscribe();
        this.mesesSubscription.unsubscribe();
        this.disSubscription.unsubscribe();
        this.entrefechasSubscription.unsubscribe();
    }

    getentreFechas(user, inicio, fin) {
        this.hastaFecha = true;
        this._empleados.getFichajesEntreFechas(user.id, inicio, fin);
    }

    getTotalCost() {
        if (this.arreglados) {
            return this.arreglados.map(t => t.horas).reduce((acc, value) => acc + value, 0);
        }
    }

    modalPdf() {
        const objeto = {
            arreglados: this.arreglados,
            inicio: this.inicio,
            fin: this.fin
        };
        this.pdfemisor.emit(objeto);
    }

    changeEditarMode() {
        if (this.editarMode === true) {
            this.editarMode = false;
        } else {
            this.editarMode = true;
        }
    }

    actualizarFichaje(posicionList: any, estado: any) {
        const posicion = posicionList + (10 * (this.actualPage - 1));
        // console.log(this.arreglados[posicion]);
        // console.log('CLONADO: ', this.clonadoUltimaPosicion);
        let fichajeUpdateData = {};

        if (estado === 'ENTRADA') {
            fichajeUpdateData = {
                id: this.arreglados[posicion].id_entrada,
                hour: this.arreglados[posicion].timeEntrada.hour,
                minute: this.arreglados[posicion].timeEntrada.minute
            }
        }

        if (estado === 'SALIDA') {
            fichajeUpdateData = {
                id: this.arreglados[posicion].id_salida,
                hour: this.arreglados[posicion].timeSalida.hour,
                minute: this.arreglados[posicion].timeSalida.minute
            }
        }

        if (fichajeUpdateData) {
            this._empleados.updateFichaje(fichajeUpdateData).subscribe(res => {
                if (res['code'] === 200) {

                    if (estado === 'ENTRADA') {
                        this.arreglados[posicion].entrada = new Date(this.arreglados[posicion].entrada.setHours(this.arreglados[posicion].timeEntrada.hour, this.arreglados[posicion].timeEntrada.minute));
                        if (this.clonadoUltimaPosicion.entrada === this.clonadoUltimaPosicion.salida && posicion === this.arreglados.length - 1) {
                            this.arreglados[posicion].salida = new Date(this.arreglados[posicion].entrada.setHours(this.arreglados[posicion].timeEntrada.hour, this.arreglados[posicion].timeEntrada.minute));
                        }
                    }
                    if (estado === 'SALIDA') {
                        this.arreglados[posicion].salida = new Date(this.arreglados[posicion].salida.setHours(this.arreglados[posicion].timeSalida.hour, this.arreglados[posicion].timeSalida.minute));
                    }

                    this.arreglados[posicion].horas = (this.arreglados[posicion].salida.valueOf() - this.arreglados[posicion].entrada.valueOf()) / 3600000;
                }
            }, error => {
                // console.log(error);
                return error;
            })
        }
    }

    editButton() {
        if (this.editarMode) {
            return 'btn btn-secondary m-0';
        } else {
            return 'btn btn-outline-secondary m-0';
        }
    }

    getentreFechasInicio(dato: Datos, inicio: Date, fin: Date) {
        if (this.hastaFecha) {
            this.getentreFechas(dato, inicio, fin);
        }
    }

    private montarArrayTablaFichajes() {
        // console.log('FICHAJES: ', this.fichajes);
        this.arreglados = [];

        let i = 0;
        while (i < this.fichajes.length) {
            let entrada: any;
            let salida: any;

            entrada = this.fichajes[i];
            if (this.fichajes[i + 1]) {
                if (new Date(this.fichajes[i + 1].fecha.date).getDate() === new Date(this.fichajes[i].fecha.date).getDate()) {
                    salida = this.fichajes[i + 1];
                } else {
                    salida = this.fichajes[i];
                }
            } else {
                salida = this.fichajes[i];
            }

            const fila = {
                dia: new Date(entrada.fecha.date),
                entrada: new Date(entrada.fecha.date),
                id_entrada: entrada.id_fichaje,
                salida: new Date(salida.fecha.date),
                id_salida: salida.id_fichaje,
                timeEntrada: {
                    hour: new Date(entrada.fecha.date).getHours(),
                    minute: new Date(entrada.fecha.date).getMinutes()
                },
                timeSalida: {
                    hour: new Date(salida.fecha.date).getHours(),
                    minute: new Date(salida.fecha.date).getMinutes()
                },
                horas: (new Date(salida.fecha.date).valueOf() - new Date(entrada.fecha.date).valueOf()) / 3600000
            };

            this.arreglados.push(fila);

            i = i + 2;
        }

        if (this.arreglados.length > 0) {
            // Clonar Última Posición
            this.clonadoUltimaPosicion = JSON.parse(JSON.stringify(this.arreglados[this.arreglados.length - 1]));

        }
    }

    montadoarray(entrefechas) {
        // console.log('ENTRE FECHAS: ', entrefechas);
        // this.arreglados = [];
        // entrefechas.forEach(element => {
        //     const fecha = new Date(element.fecha.date);
        //     const arrayfichajes = this.fichajes.filter(fichaje =>
        //         new Date(fichaje.fecha.date).getDate() === fecha.getDate()
        //     );
        //     console.log('ARRAY F: ', arrayfichajes);
        //     let i = 0;
        //     while (i < arrayfichajes.length) {
        //         console.log('ITERACION: ', i);
        //         console.log('ARRAY LEN:', arrayfichajes.length);
        //         let entrada: any;
        //         let salida: any;
        //
        //         entrada = arrayfichajes[i];
        //         if (arrayfichajes[i + 1]) {
        //             salida = arrayfichajes[i + 1];
        //         } else {
        //             salida = arrayfichajes[i];
        //         }
        //
        //         const fila = {
        //             dia: new Date(entrada.fecha.date),
        //             entrada: new Date(entrada.fecha.date),
        //             id_entrada: entrada.id_fichaje,
        //             salida: new Date(salida.fecha.date),
        //             id_salida: salida.id_fichaje,
        //             timeEntrada: {
        //                 hour: new Date(entrada.fecha.date).getHours(),
        //                 minute: new Date(entrada.fecha.date).getMinutes()
        //             },
        //             timeSalida: {
        //                 hour: new Date(salida.fecha.date).getHours(),
        //                 minute: new Date(salida.fecha.date).getMinutes()
        //             },
        //             horas: (new Date(salida.fecha.date).valueOf() - new Date(entrada.fecha.date).valueOf()) / 3600000
        //         };
        //
        //         this.arreglados.push(fila);
        //         i = i + 2;
        //     }
        // });
    }

    // DESECHADO POR USAR OTRAS FUNCIONALIDADES
    // -----------------------------------------------------------------------------
    porAno(): void {
        // HAGO EL SELECT DE FICHAJES SEGUN AÑO
        this._empleados.getFichajesEmpleadoFecha(this.id, 'ano', Number(this.ano));
        this._empleados.getMesesEmpleado(this.id, Number(this.ano));
    }

    porMes() {
        // HAGO EL SELECT DE FICHAJES SEGUN MES
        this._empleados.getFichajesEmpleadoFecha(this.id, 'mes', Number(this.ano), Number(this.mes));
        this._empleados.getDiasEmpleado(this.id, Number(this.ano), Number(this.mes));
    }

    porDia() {
        // HAGO EL SELECT DE FICHAJES SEGUN DIA
        this._empleados.getFichajesEmpleadoFecha(this.id, 'dia', Number(this.ano), Number(this.mes), Number(this.dia));
    }


    // DESECHADO POR SIMPLIFICADO EN EL BACKEND
    // -----------------------------------------------------------------------------
    filtradoFichajes() {
        // RECORRO LAS FECHAS SELECCIONADAS
        this.entrefechas.forEach(element => {
            const entradas = Number(element.entradas);
            const dianumerico = new Date(element.fecha.date).getDay();
            const fecha = element.fecha.date;

            // OBTENGO LOS HORARIOS DE ESE DIA
            const horarios = this.dato.horarios.filter(hor =>
                dianumerico === hor.dia
            );
            const numerohorarios = horarios.length * 2;

            // OBTENGO EL HORARIO DE ESE DIA
            const horario = this.dato.horarios.find(hor =>
                dianumerico === hor.dia
            );

            // OBTENGO LOS FICHAJES DE ESE DIA QUE NO ESTA CERRADO PARA COMPARARLO CON EL HORARIO
            const fichajesdia = this.fichajes.filter(fichaje =>
                fecha.substr(0, 10) === fichaje.fecha.date.substr(0, 10)
            );

            // COMPROBAR QUE EL NUMERO DE FICHAJES ES IGUAL AL DE ENTRADAS DEL DIA DEL HORARIO
            if (entradas === numerohorarios) {
                // COMPROBAR QUE LAS HORAS ESTAN BIEN, SI NO ESTA BIEN HACER UPDATE A LA HORA QUE TOCA
                // console.log('HAY LOS MISMOS FICHAJES QUE EN EL HORARIO ESTABLECIDO');
                // console.log(fichajesdia);
                // console.log(horarios);

            } else if (entradas > numerohorarios) {
                // ELIMINAR LOS FICHAJES QUE ESTEN FUERA DEL MARGEN DE FICHAJE
                // console.log('HAY MAS FICHAJES QUE EN EL HORARIO ESTABLECIDO');
                // console.log(fichajesdia);
                // console.log(horarios);
                const eliminar = [];

                for (let i = 0; i < fichajesdia.length; i++) {
                    const horafichaje = new Date(fichajesdia[i].fecha.date).getHours();
                    const minutofichaje = new Date(fichajesdia[i].fecha.date).getMinutes();
                }

                // console.log(eliminar);

            } else if (entradas < numerohorarios) {
                // console.log('HAY MENOS FICHAJES QUE EN EL HORARIO ESTABLECIDO');
                // console.log(fichajesdia);
                // console.log(horarios);

                // SI EL PRIMER FICHAJE NO ES ENTRADA
                if (fichajesdia[0].estado !== 'ENTRADA') {
                    const user = {
                        id: this.dato.id,
                        hora: Number(horario.inat),
                        fecha: fecha.substr(0, 10),
                        estado: 'ENTRADA',
                        inicio: this.inicio,
                        fin: this.fin,
                    };
                    // console.log(user);
                    // this._empleados.forzarFichaje(user);

                    // SI EL ULTIMO FICHAJE NO ES SALIDA
                } else if (fichajesdia[fichajesdia.length - 1] !== 'SALIDA') {
                    const user = {
                        id: this.dato.id,
                        hora: Number(horario.outat),
                        fecha: fecha.substr(0, 10),
                        estado: 'SALIDA',
                        inicio: this.inicio,
                        fin: this.fin,
                    };
                    // console.log(user);
                    // this._empleados.forzarFichaje(user);
                }
            }
        });
    }

    eliminarFichaje(id_entrada: any, id_salida: any, posicion: any) {
        const ids_entrada_salida = {
            id_entrada: id_entrada,
            id_salida: id_salida
        }

        // @ts-ignore
        swal({
            title: 'BORRAR',
            text: '¿Seguro que quiere borrar el fichaje?',
            icon: 'error',
            buttons: true,
            dangerMode: true
        }).then((willDelete) => {
            if (willDelete) {
                this._empleados.removeFichaje(ids_entrada_salida).subscribe(res => {
                    if (res['code'] === 200) {
                        swal('Se ha eliminado el fichaje', {
                            icon: 'success',
                            timer: 3000
                        });

                        this.arreglados.splice(posicion, 1);
                    }
                }, error => {
                    swal({
                        title: "ERROR",
                        text: "Se ha producido un error inesperado",
                        icon: "error",
                        timer: 3000
                    });
                });
            }
        });
    }

    addFichaje() {

        if (this.fechaAdd) {
            // @ts-ignore
            swal({
                title: 'AÑADIR FICHAJE',
                text: '¿Seguro que quiere añadir el fichaje?',
                icon: 'info',
                buttons: true,
                dangerMode: true
            }).then((willDelete) => {
                if (willDelete) {
                    const fichajeAdd = {
                        id: this.dato.id,
                        fecha: this.fechaAdd,
                        timeEntrada: this.timeEntradaAdd,
                        timeSalida: this.timeSalidaAdd
                    }

                    this._empleados.addFichaje(fichajeAdd).subscribe(res => {
                        if (res['code'] === 200) {
                            swal('Se ha creado el nuevo fichaje', {
                                icon: 'success',
                                timer: 3000
                            });
                            this.getentreFechas(this.dato, this.dateAString(this.inicio), this.dateAString(this.fin));
                        }
                    }, error => {
                        swal({
                            title: "ERROR",
                            text: "Se ha producido un error inesperado",
                            icon: "error",
                            timer: 3000
                        });
                    });
                }
            });
        } else {
            swal({
                title: "ERROR",
                text: "Completa todos los datos",
                icon: "warning",
                timer: 3000
            });
        }
    }

    dateAString(date) {
        const fecha = new Date(date).toISOString();
        const trozos = fecha.split('T');
        return trozos[0];
    }
}
