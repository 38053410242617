import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {EmpleadosService} from "../../empleados/services/empleados.service";
import {EmpresaService} from "../services/empresa.service";
import swal from 'sweetalert';
import {ActivatedRoute, Router} from "@angular/router";
import {LoginService} from "../../../../pages/services/login.service";


@Component({
    selector: 'app-empresa',
    templateUrl: './empresa.component.html',
    styleUrls: ['./empresa.component.css']
})
export class EmpresaComponent implements OnInit, OnDestroy {

    private empresaSubscription: Subscription = null;
    empresas: any[] = [];


    @Output() empresaId: EventEmitter<number> = new EventEmitter();

    constructor(private _empresa: EmpleadosService,
                private _empresaRemove: EmpresaService,
                private router: Router,
                private route: ActivatedRoute,
                private _login: LoginService) {
    }

    ngOnInit() {
        this.empresaSubscription = this._empresa.empresas$.subscribe(res => {
            this.empresas = res;
        });
        this._empresa.getEmpresas();
        this._empresaRemove.getEmpresas();
    }

    ngOnDestroy() {
        this.empresaSubscription.unsubscribe();
    }


    verEmpresa(id) {
        this.empresaId.emit(id);
        this.router.navigate(['/admin/localizacion/', id]).then(r => r);
    }


    eliminarEmpresa(id: number) {
        const empresaNombre = this.empresas[this.empresas.findIndex(empresa => empresa.id === id)].nombre;
        // @ts-ignore
        swal({
            title: 'BORRAR',
            text: '¿Seguro que quiere borrar ' + empresaNombre + '?',
            icon: 'error',
            buttons: true,
            dangerMode: true
        }).then((willDelete) => {
            if (willDelete) {
                this._empresaRemove.removeEmpresa(id).subscribe(res => {
                    if (res['code'] === 200) {
                        swal('Se ha eliminado ' + empresaNombre + '.', {
                            icon: 'success',
                            timer: 3000
                        });
                        this.empresas.splice(this.empresas.findIndex(empresa => empresa.id === id), 1);
                    } else {
                        swal({
                            title: "ERROR",
                            text: "Existen usuarios asignados en esta empresa.",
                            icon: "error",
                            timer: 3000
                        });
                    }
                }, error => {
                    console.log(error);
                });
            }
        });
    }
}
