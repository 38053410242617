import {Component, OnInit, Input} from '@angular/core';
import {RutasImg} from '../../../../utiles/rutas';
import {MatTableDataSource} from '@angular/material/table';
import {ExportAsService, ExportAsConfig} from 'ngx-export-as';
import {DatePipe} from '@angular/common';

import * as html2pdf from 'html2pdf.js';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';

declare const $: any;


export interface Fichaje {
    fecha: Date;
    entrada: Date;
    salida: Date;
    horas: number;
}

@Component({
    selector: 'app-pdfmodal',
    templateUrl: './pdf.component.html',
    styleUrls: ['./pdf.component.css']
})
export class PdfComponent implements OnInit {
    imageService: any;
    imageToShow: any;
    @Input() public fichajes: any[];
    @Input() public arreglados = new MatTableDataSource<Fichaje>();
    @Input() public user: any;
    @Input() public empresa: any;
    @Input() public inicio: any;
    @Input() public fin: any;
    public rutaimg: any;

    public displaysColumns: string[] = ['fecha', 'entrada', 'salida', 'horas'];
    public dataSource: any;

    exportAsConfig: ExportAsConfig = {
        type: 'pdf',
        elementId: 'contenidopdf',
        options: {
            orientation: 'landscape',
            margin: [20, 5],
            pagebreak: {avoid: 'td'},
        },
    };

    constructor(private exportAsService: ExportAsService,
                private datepipe: DatePipe,
                private http: HttpClient) {
        this.rutaimg = RutasImg;
    }

    ngOnInit() {

    }

    getTotalCost() {
        if (this.fichajes) {
            return this.fichajes.map(t => t.horas).reduce((acc, value) => acc + value, 0);
        }
    }

    /*    lanzarPdf() {
            const element = document.getElementById('contenidopdf');
            const options = {
                filename: `Seguimiento ${this.user.nombre} ${this.trans(this.inicio)} ${this.trans(this.fin)}.pdf`,
                margin: [15, 15],
                pagebreak: {avoid: 'tr'},
                image: { type:'jpeg' , quality: 0.98},
                html2canvas: {
                    y: 0,
                    scrollY: 0,
                    height: element.clientHeight + 200
                },
            };
            html2pdf().set(options).from(element).save();
            $('#pdfMod').modal('hide');
        }*/

    lanzarPdf() {
        const element = document.getElementById('contenidopdf');
        const options = {
            filename: `Seguimiento ${this.user.nombre} ${this.trans(this.inicio)} ${this.trans(this.fin)}.pdf`,
            margin: [15, 15],
            image: {type: 'jpeg', quality: 0.98},
            html2canvas:  {  y: 0,
                scrollY: 0,
                height: element.clientHeight + 200 },
        };

        html2pdf().from(element).set(options).save();
        $('#pdfMod').modal('hide');
    }

    trans(value) {
        return this.datepipe.transform(value, 'dd-MM-yyyy');
    }


    // INTENTO DE PASAR A BASE64 LA IMAGEN PARAQ VER SI LA IMAGEN SE VE AL LANZAR EL PDF
    /*
    getImage(imageUrl: string): Observable<File> {
      return this.http.get(imageUrl, { responseType: ResponseContentType.Blob }).map((res: any) => res.blob());
    }

    createImageFromBlob(image: Blob) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const imageToShow = reader.result;
        // here you can save base64-image to session/localStorage
        localStorage.setItem('yourKey', imageToShow);
      }, false);

      if (image) {
        reader.readAsDataURL(image);
      }
      return reader.result;
    }

    getImageFromService(nombre) {
      const url = this.rutaimg + nombre;
      this.getImage(url).subscribe(data => {
        return this.createImageFromBlob(data);
      }, error => {
        return this.rutaimg + 'images/centro/persona_default.png';
        console.log(error);
      });
    }
    */


}
