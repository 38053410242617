<div class="row">
    <div class="col-md-12 col-sm-12">

        <form class="form form-horizontal" novalidate="" #contactoForm="ngForm">
            <input type="text" class="form-control border-dark" name="fantasma" [(ngModel)]="fantasma" hidden required>

            <!-- <div class="col-md-4 col-sm-12 float-left ">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <button class="btn btn-dark opa-1" type="button" disabled>
                            <i class="fas fa-envelope"></i>
                        </button>
                    </div>
                    <input id="mail" class="form-control" placeholder="Email" type="email" name="mail{{i}}" [(ngModel)]="contacto.dato" pattern="^[a-zA-Z0-9_\-\.~]{2,}@[a-zA-Z0-9_\-\.~]{2,}\.[a-zA-Z]{2,4}$"
                        (blur)="controlValido(false, 'mail')" (focus)="controlValido(true, 'mail')" required>
                    <div class="input-group-append">
                        <button class="btn btn-dark" type="button" (click)="open(1, 'mail')">
                            <i class="icon-plus"></i>
                        </button>
                    </div>
                </div>

                <div class="list-group">
                    <ng-container *ngFor="let contacto of dato.contactos; let i = index;">
                        <div *ngIf="contacto.tipo === 1" class="input-group">
                            <input type="text" class="form-control border-dark" name="mail{{i}}" [(ngModel)]="contacto.dato" required disabled>
                            <div class="input-group-append" id="button-addon4">
                                <button class="btn btn-outline-danger" type="button" (click)="borrar(i)">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div> -->

            <div class="col-md-6 col-sm-12 float-left ">
                <label>MÓVIL(*)</label>
                <div class="input-group mb-3" *ngIf="role === 'ROLE_ADMIN'">
                    <div class="input-group-prepend">
                        <button class="btn btn-dark opa-1 min-w-42" type="button" disabled>
                            <i class="fas fa-mobile-alt "></i>
                        </button>
                    </div>

                    <input id="mov" class="form-control" placeholder="Móvil" type="tel"
                           pattern="^(\+34|0034|34)?[6789][0-9]{8}$" (blur)="controlValido(false, 'mov')"
                           (focus)="controlValido(true, 'mov')" required>
                    <div class="input-group-append">
                        <button class="btn btn-dark" type="button" (click)="open(2, 'mov')">
                            <i class="icon-plus"></i>
                        </button>
                    </div>
                </div>

                <div class="list-group" *ngIf="dato.contactos && dato.contactos.length > 0">
                    <div class="input-group-prepend" *ngIf="role !== 'ROLE_ADMIN'">
                        <span class="input-group-text-contacto-title bg-dark text-light border-dark">Móvil</span>
                    </div>
                    <ng-container *ngFor="let contacto of dato.contactos; let i = index;">
                        <div *ngIf="contacto.tipo === 2" class="input-group">
                            <input type="text" class="form-control border-dark" name="movil{{i}}"
                                   [(ngModel)]="contacto.dato" required disabled>
                            <div class="input-group-append" id="button-addon4" *ngIf="role === 'ROLE_ADMIN'">
                                <button class="btn btn-outline-danger" type="button" (click)="borrar(i)">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="col-md-6 col-sm-12 float-left ">
                <label>FIJO</label>
                <div class="input-group mb-3" *ngIf="role === 'ROLE_ADMIN'">
                    <div class="input-group-prepend">
                        <button class="btn btn-dark opa-1" type="button" disabled>
                            <i class="fas fa-phone"></i>
                        </button>
                    </div>

                    <input id="tel" class="form-control" placeholder="Teléfono" type="tel"
                           pattern="^(\+34|0034|34)?[6789][0-9]{8}$" (blur)="controlValido(false, 'tel')"
                           (focus)="controlValido(true, 'tel')">
                    <div class="input-group-append">
                        <button class="btn btn-dark" type="button" (click)="open(3, 'tel')">
                            <i class="icon-plus"></i>
                        </button>
                    </div>
                </div>

                <div class="list-group" *ngIf="dato.contactos && dato.contactos.length > 0">
                    <div class="input-group-prepend" *ngIf="role !== 'ROLE_ADMIN'">
                        <span class="input-group-text-contacto-title bg-dark text-light border-dark">Teléfono</span>
                    </div>
                    <ng-container *ngFor="let contacto of dato.contactos; let i = index;">
                        <div *ngIf="contacto.tipo === 3" class="input-group">
                            <input type="text" class="form-control border-dark" name="tel{{i}}"
                                   [(ngModel)]="contacto.dato" required disabled>
                            <div class="input-group-append" id="button-addon4" *ngIf="role === 'ROLE_ADMIN'">
                                <button class="btn btn-outline-danger" type="button" (click)="borrar(i)">
                                    <i class="fas fa-trash"></i>
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 text-center mt-3">
                    <button  type="submit" mat-button matStepperPrevious class="btn btn-dark btn-sm center-block mr-2"><i
                            class="fas fa-arrow-alt-circle-left"></i> Anterior</button>
                    <button type="submit" mat-button matStepperNext class="btn btn-dark btn-sm center-block"><i
                            class="fas fa-arrow-alt-circle-right"></i> Siguiente
                    </button>
                </div>
            </div>
        </form>

    </div>

</div>
