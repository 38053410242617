import {
    Component, OnInit, OnDestroy, AfterViewInit, ViewChild, Output, EventEmitter, ElementRef, TemplateRef
} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {FormGroup, NgForm} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {MatStep} from '@angular/material';
import {Datos} from '../../../../../modelos/datos.modelo';
import {DatosComponent} from '../componentes/datos/datos.component';
import {ContactoComponent} from '../componentes/contacto/contacto.component';
import {EstudiosComponent} from '../componentes/estudios/estudios.component';
import {MyModalComponent} from '../../../modales/my-modal/my-modal.component';
import {CrudempleadosService} from '../services/crudempleados.service';
import {UploadService} from '../../../../services/upload.service';
import {HorarioComponent} from '../componentes/horario/horario.component';

// import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';

declare const $: any;

@Component({
    selector: 'app-addempleado',
    templateUrl: './addempleado.component.html',
    // providers: [ {
    //     provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false
    // }
    // }]
})
export class AddempleadoComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(DatosComponent) datos: DatosComponent;
    @ViewChild(ContactoComponent) contacto: ContactoComponent;
    @ViewChild(EstudiosComponent) estudios: EstudiosComponent;
    @ViewChild(HorarioComponent) horario: HorarioComponent;

    public userSubscription = new Subscription();
    // public isLinear = true;
    public firstFormGroup: NgForm;
    public secondFormGroup: NgForm;
    public thirdFormGroup: NgForm;
    public fourthFormGroup: NgForm;
    public filesToUpload: Array<File>;
    public user: Datos;
    public ttlModal: string;
    public txtModal: string;
    public iduser: any;
    public inicio: Date;
    public fin: Date;

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _crud: CrudempleadosService,
        private _upload: UploadService,
    ) {
    }

    ngOnInit() {
        this.firstFormGroup = this.datos.datosForm;
        this.filesToUpload = this.datos.filesToUpload;
        this.secondFormGroup = this.contacto.contactoForm;
        this.thirdFormGroup = this.horario.horarioForm;
        this.fourthFormGroup = this.estudios.estudiosForm;

        this.userSubscription = this._crud.dato$.subscribe(res => {
            if (res) {
                this.iduser = res;
                if (this.filesToUpload) {
                    this._upload.makeFileRequest(res.id, this.filesToUpload, 'imagen', 'user').then((result: any) => {
                        if (result) {
                            this._router.navigate([
                                '/admin/empleados'
                            ]);
                        }
                    }).catch(error => {
                        // console.log(error);
                        this.ttlModal = 'ERROR DE SUBIDA';
                        this.txtModal = error;
                        $('#myModal').modal('show');
                    });
                } else {
                    this._router.navigate([
                        '/admin/empleados'
                    ]);
                }
            }
        });
    }

    ngOnDestroy() {
        this.userSubscription.unsubscribe();
    }

    ngAfterViewInit() {
    }

    guardar() {
        this.filesToUpload = this.datos.filesToUpload;

        this.user = {
            password: this.datos.dato.password,
            nombre: this.datos.dato.nombre,
            apellidos: this.datos.dato.apellidos,
            direccion: this.datos.dato.direccion,
            nacimiento: this.datos.dato.nacimiento,
            dni: this.datos.dato.dni,
            municipio: this.datos.dato.municipio,
            iban: this.datos.dato.iban,
            username: this.datos.dato.username,
            id: null,
            id_municipio: this.datos.dato.id_municipio,
            img: '',
            contactos: this.contacto.dato.contactos,
            titulos: this.estudios.dato.titulos,
            horarios: this.horario.dato.horarios,
            id_centro: this.datos.dato.id_centro,
            id_departamento: this.datos.dato.id_departamento,
            pin: this.datos.dato.pin,
            contrato: this.datos.dato.contrato,
            horas: this.datos.dato.horas,
        };
        // console.log('GUARDANDO USUARIO', this.user);
        // console.log('HORAS',this.datos.dato.horarios);

        this._crud.postUsuario(this.user);
    }

    dispararmodal(event) {
        // console.log('EVENT INICIO', event.inicio);
        // console.log('EVENT FIN', event.fin);
        this.inicio = event.inicio;
        this.fin = event.fin;

        // console.log('DISPARA MODAL', event);
        this.ttlModal = event.ttlModal;
        this.txtModal = event.txtModal;
        $('#myModal').modal('show');
    }

}
