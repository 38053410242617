import {Component, ViewChild, OnDestroy, OnInit, ViewEncapsulation, Output, EventEmitter} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {Datos} from '../../../../../../modelos/datos.modelo';
import {CrudempleadosService} from '../../services/crudempleados.service';
import {LoginService} from "../../../../../pages/services/login.service";

declare var $;

@Component({
    selector: 'app-horario',
    templateUrl: './horario.component.html',
    encapsulation: ViewEncapsulation.None
})

export class HorarioComponent implements OnInit, OnDestroy {
    @ViewChild('horarioForm') horarioForm: NgForm;
    @Output() public emisor = new EventEmitter<any>();
    public fantasma: any;
    public dato: Datos;
    public dias: any[];
    public horas: any[];
    timeEntrada = {hour: 9, minute: 0};
    timeSalida = {hour: 14, minute: 0};
    role = '';

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _crud: CrudempleadosService,
        private _login: LoginService
    ) {
        this.role = this._login.getIdentity().role;
        this.dato = {
            password: '',
            nombre: '',
            apellidos: '',
            direccion: '',
            nacimiento: null,
            dni: '',
            municipio: '',
            iban: '',
            username: '',
            id: null,
            id_municipio: 0,
            img: '',
            contactos: [],
            titulos: [],
            horarios: [],
            id_centro: 0,
            id_departamento: 0,
            pin: 0,
            contrato: 0,
            horas: 0,
        };
        this.dias = [
            {valor: 1, nombre: 'Lunes'},
            {valor: 2, nombre: 'Martes'},
            {valor: 3, nombre: 'Miércoles'},
            {valor: 4, nombre: 'Jueves'},
            {valor: 5, nombre: 'Viernes'},
            {valor: 6, nombre: 'Sabado'},
            {valor: 0, nombre: 'Domingo'},
        ];
        this.horas = [
            {valor: 0, nombre: '00'}, {valor: 1, nombre: '01'}, {valor: 2, nombre: '02'},
            {valor: 3, nombre: '03'}, {valor: 4, nombre: '04'}, {valor: 5, nombre: '05'},
            {valor: 6, nombre: '06'}, {valor: 7, nombre: '07'}, {valor: 8, nombre: '08'},
            {valor: 9, nombre: '09'}, {valor: 10, nombre: '10'}, {valor: 11, nombre: '11'},
            {valor: 12, nombre: '12'}, {valor: 13, nombre: '13'}, {valor: 14, nombre: '14'},
            {valor: 15, nombre: '15'}, {valor: 16, nombre: '16'}, {valor: 17, nombre: '17'},
            {valor: 18, nombre: '18'}, {valor: 19, nombre: '19'}, {valor: 20, nombre: '20'},
            {valor: 21, nombre: '21'}, {valor: 22, nombre: '22'}, {valor: 23, nombre: '23'},
        ];
    }

    ngOnInit() {
    }

    ngOnDestroy() {
    }

    open(id, idin, idout, idm) {
        const dia = $('#' + id);
        const hin = $('#' + idin);
        const hout = $('#' + idout);
        const margen = $('#' + idm);

        // SI TODOS LOS INPUTS ESTAN LLENOS
        // hin[0].validity.valid && hout[0].validity.valid &&
        if (dia[0].validity.valid && margen[0].validity.valid) {
            // ES UN POST NUEVO. LA id ESTA VACIA
            if (this.dato.id === null) {

                const item = {

                    dia: Number(dia.val()),
                    inat: this.timeEntrada,
                    outat: this.timeSalida,
                    margen: Number(margen.val()),

                };

                this.dato.horarios.push(item);
                this.fantasma = 'lleno';
                dia.val('');
                hin.val('');
                hout.val('');
                margen.val('');
                this.controlPaso();

                // ES UN PUT. LA id ESTA LLENA
            } else {

                const item = {
                    id: this.dato.id,
                    dia: Number(dia.val()),
                    inat: this.timeEntrada,
                    outat: this.timeSalida,
                    margen: Number(margen.val()),
                };

                this._crud.addHorario(item);
                dia.val('');
                hin.val('');
                hout.val('');
                margen.val('');
            }

            // SI ALGUN INPUT ESTA INVALIDO
        } else {
            this.controlValido(id);
            this.controlValido(idm);
            // this.controlValido(idin);
            // this.controlValido(idout);

            const alerta = {
                ttlModal: 'Alerta',
                txtModal: 'Revise los campos en rojo'
            };
            this.emisor.emit(alerta);
        }
    }

    controlValido(id) {
        const elemento = $('#' + id);
        if (!elemento[0].validity.valid) {
            if (!elemento[0].classList.contains('ng-invalid')) {
                elemento[0].classList.add('ng-invalid');
                elemento[0].classList.add('ng-pristine');
                elemento[0].classList.add('ng-touched');
            }
        } else {
            if (elemento[0].classList.contains('ng-invalid')) {
                elemento[0].classList.toggle('ng-invalid');
                elemento[0].classList.toggle('ng-pristine');
                elemento[0].classList.toggle('ng-touched');
            }
        }
    }

    borrar(i) {
        if (this.dato.id === null) {
            this.dato.horarios.splice(i, 1);
            this.controlPaso();
        } else {
            const idt = this.dato.horarios[i].id;
            this._crud.removeHorario(this.dato.id, idt);
            this.controlPaso();
        }
    }

    controlPaso() {
        if (this.dato.horarios.length === 0) {
            this.fantasma = undefined;
            const alerta = {
                ttlModal: 'Alerta',
                txtModal: 'Al menos debe introducir un horario'
            };
            this.emisor.emit(alerta);
        }
    }

    diasemana(i: number) {
        if (i === 0) {
            return 'Domingo';
        } else {
            return this.dias[i - 1].nombre;
        }
    }

}
