<div class="col-xs-12 col-md-12 row mt-2">
    <div class=" form-group col-md-4 col-sm-12" *ngIf="empresas">
        <select class="form-control" ngModel name="modulos_educativos" [(ngModel)]="empresa" (change)="porEmpresa()">
            <option value="null">Mostrar por empresa</option>
            <option *ngFor="let e of empresas; let i = index" [value]="e.id">{{e.nombre}}</option>
        </select>
    </div>
    <div class=" form-group col-md-4 col-sm-12" *ngIf="fechas">
        <select class="form-control" ngModel name="modulos_educativos" [(ngModel)]="departamento" (change)="porDepartamento()" [disabled]="empresa === null">
            <option value="null">Mostrar por departamento</option>
            <option *ngFor="let d of departamentos; let i = index" [value]="d.id">{{d.departnombre}}</option>
        </select>
    </div>
    <div class=" form-group col-md-4 col-sm-12" *ngIf="fechas">
        <select class="form-control" ngModel name="modulos_educativos" [(ngModel)]="fecha" (change)="porFecha()" [disabled]="departamento === null">
            <option value="">Mostrar por fecha</option>
            <option *ngFor="let f of fechas; let i = index" [value]="f.fechas">{{f.fechas}}</option>
        </select>
    </div>
</div>

<div class="card mt-2">
    <div class="card-header">
        <i class="fas fa-table"></i>
        Registro Seguimiento Empleados</div>
    <div class="card-body">
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="imagen">
                <th mat-header-cell *matHeaderCellDef> IMAGEN </th>
                <td mat-cell *matCellDef="let element">
                    <div class="avatar javier" [ngStyle]="{'background-image':'url(' + rutaimg + element.img  + ')'}"></div>
                </td>
            </ng-container>

            <ng-container matColumnDef="nombre">
                <th mat-header-cell *matHeaderCellDef> NOMBRE </th>
                <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
            </ng-container>

            <ng-container matColumnDef="apellidos">
                <th mat-header-cell *matHeaderCellDef> APELLIDOS </th>
                <td mat-cell *matCellDef="let element"> {{element.apellidos }} </td>
            </ng-container>

            <ng-container matColumnDef="fecha">
                <th mat-header-cell *matHeaderCellDef> FECHA </th>
                <td mat-cell *matCellDef="let element" [ngClass]="{ayer:!empieza(element.fecha) && fecha === ''}"> {{element.fecha | date: 'HH:mm dd/MM/yyyy' | fichar: empieza(element.fecha):false:fecha}} </td>
            </ng-container>

            <ng-container matColumnDef="estado">
                <th mat-header-cell *matHeaderCellDef> ESTADO </th>
                <td mat-cell *matCellDef="let element" [ngClass]="{ayer:!empieza(element.fecha) && fecha === ''}"> {{element.estado | fichar: empieza(element.fecha):false:fecha}} </td>
            </ng-container>

            <ng-container matColumnDef="distancia">
                <th mat-header-cell *matHeaderCellDef> DISTANCIA </th>
<!--                <td mat-cell *matCellDef="let element" [ngClass]="{fuera: element.distancia > 100 && !empieza(element.fecha), dentro: element.distancia < 100, ayer: !empieza(element.fecha) && fecha === ''}">-->
                <td mat-cell *matCellDef="let element" [ngClass]="colorDistancia(element.distancia, element.idCentro)">
                {{element.distancia | number:'1.2-2' | fichar: empieza(element.fecha):true:fecha }} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displaysColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displaysColumns;"></tr>
        </table>

        <mat-paginator [length]="fichados.length" [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </div>
    <div class="card-footer small text-muted">A {{hoy | date:'EEEE dd MMMM yyyy' | titlecase}}</div>
</div>
<!-- /.container-fluid -->
