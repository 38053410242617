// Importamos los componentes necesarios
import { Pipe, PipeTransform } from '@angular/core';

// Le ponemos un nombre a la tuberia
@Pipe({ name: 'fichar' })

// Definimos la clase implementado la interface PipeTransform
export class FicharPipe implements PipeTransform {
  transform(value: string, hoy: boolean, tipo: boolean, fecha: any): string {
    const fechahoy = new Date().toISOString().substring(0, 10);
    if (fecha === '') {
      if (hoy) {
        if (tipo) {
          return value + ' m';
        } else {
          return value;
        }
      } else {
        return 'POR FICHAR';
      }

    } else {
      if (tipo) {
        return value + ' m';
      } else {
        return value;
      }
    }

  }

}
